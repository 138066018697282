import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardHeader } from "../../../components/buyer/home/DashboardHeader"
import logo from "../../../assets/buyer/image.jpg";
import { $crud } from "../../../utils/CrudFactory";

const LeadDetail = () => {
  const navigate = useNavigate();
  // const { leadId } = useParams();
  const { _id } = useParams();
  const [leadData, setLeadData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const columns = [
    // { key: "leadId", label: "Lead ID" },
    { key: "_id", label: "Lead ID" },
    { key: "service.category", label: "Category" },
    { key: "distance", label: "Distance (km)" },
    { key: "serviceNotes", label: "Service Notes" },
    { key: "pincode", label: "Pincode" },
    { key: "createdAt", label: "Created At" },
    { key: "leadSalePrice", label: "Sale Price" },
    { key: "requester.email", label: "Requester Email" },
    { key: "requester.phone", label: "Requester Phone" },
  ];

  const getLeadValue = (key) => {
    const parts = key.split(".");
    let value = leadData;
    for (const part of parts) {
      if (value && value.hasOwnProperty(part)) {
        value = value[part];
      } else {
        return ""; // Handle missing data gracefully
      }
    }
    return value;
  };

  useEffect(() => {
    async function fetchLeadDetails() {
      setLoading(true);
      try {
        const { data } = await $crud.retrieve(`/buyer/leads`);
        console.log("API Response:", data); // Debugging API response.
        // console.log("leadId from URL:", leadId); // Debugging leadId from URL.
        console.log("leadId from URL:", _id); // Debugging leadId from URL.

        // Compare leadId as string
        const selectedLead =
          // data.leads?.find((lead) => String(lead.leadId) === String(leadId)) ||
          // null;
          data.leads?.find((lead) => String(lead._id) === String(_id)) ||
          null;
        console.log("Selected Lead:", selectedLead); // Debugging selected lead.

        setLeadData(selectedLead);
        if (!selectedLead) {
          setError(`No lead found for ID: ${_id}`);
          // setError(`No lead found for ID: ${leadId}`);
        }
      } catch (err) {
        console.error("Error fetching lead details:", err); // Debugging error.
        setError("Failed to load lead details. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
    fetchLeadDetails();
  // }, [leadId]);
  }, [_id]);

  if (loading) {
    return <p>Loading lead details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // if (!leadData) {
  //   return <p>No lead found with ID: {leadId}</p>;
  // }
  if (!leadData) {
    return <p>No lead found with ID: {_id}</p>;
  }

  return (
    <div className="font-Rubik">
      <DashboardHeader />
      <div className="bg-white flex items-center gap-10 p-14 h-[650px] w-full">
        <div className="bg-stone-100 h-full rounded-md w-[20%]"></div>
        <div className="bg-stone-100 h-full rounded-md p-10 w-[80%]">
          <p className="font-medium text-stone-700 border-b border-stone-300 text-2xl">
            {/* #{leadData.leadId} */}
            #{leadData._id}
          </p>
          <div className="mt-5 relative flex gap-10">
            <img src={logo} className="h-80 w-72 object-contain" alt="Lead" />
            <div className="w-full bg-white border border-stone-300 p-6 h-full mt-4 rounded-2xl">
              <div className="flex justify-around mt-3 items-center">
                <div className="text-stone-600 space-y-3">
                  {columns.slice(0, 4).map((column) => (
                    <p key={column.key}>
                      {column.label}: {getLeadValue(column.key)}
                    </p>
                  ))}
                </div>
                <div className="space-y-3 text-stone-600">
                  {columns.slice(4).map((column) => (
                    <p key={column.key}>
                      {column.label}: {getLeadValue(column.key)}
                    </p>
                  ))}
                </div>
              </div>
              <div className="flex border-t items-center justify-end gap-3 p-2 mt-10">
                <button className="bg-red-400 rounded-md p-1 px-3 mt-5 text-white">
                  Cancel
                </button>
                <button
                  className="bg-blue-400 rounded-md p-1 px-3 mr-10 mt-5 text-white"
                  onClick={() => navigate("/leads/buylead")}
                >
                  Buy Lead
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadDetail;
