import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window8.propTypes = {
  dispatch: PropTypes.func.isRequired,
  num: PropTypes.number,
  phoneverified: PropTypes.bool,
  cancel: PropTypes.func,
};

function Window8({ dispatch, cancel, phoneverified, num }) {
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({
                type: "set-num",
                payload: !phoneverified ? num - 2 : num - 1,
              });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="animate-fade">
            <p className="mt-12 text-center text-lg">Referral Check</p>
            <p className="mt-5 px-1 sm:px-0 text-center">
              Is this request for yourself or someone else?
            </p>
            <div className="mt-5 flex h-5 justify-center items-center gap-2 w-full">
              <button
                onClick={() => {
                  dispatch({ type: "set-no-refer" });
                }}
                className="rounded-md mt-3 p-1 px-5 bg-light-background-neww text-white"
              >
                Myself
              </button>
              <button
                onClick={() => {
                  dispatch({ type: "set-refer" });
                }}
                className="rounded-md mt-3 p-1 px-5 border-2 border-light-background-neww text-light-background-neww"
              >
                Someone Else
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window8;
