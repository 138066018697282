import { useState } from "react";
import { useNavigate } from "react-router-dom";

function PayAndBuyLead() {
  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
    cvv: "",
  });

  const paymentDetails = [
    { label: "Lead cost", value: "$5" },
    { label: "GST/HST (13%)", value: "$0.65" },
    { label: "Total", value: "$5.65", isTotal: true },
  ];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Name:", formData.name);
    console.log("Card Number:", formData.cardNumber);
    console.log("CVV:", formData.cvv);
  };

  const navigate = useNavigate()

  return (
    <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl max-w-screen-sm sm:max-w-xl mx-auto flex flex-col mt-32">
      <h2 className="text-2xl font-semibold mb-6">Payment</h2>
      {paymentDetails.map(({ label, value, isTotal }, index) => (
        <div key={index} className="flex justify-between mb-4">
          <p
            className={`text-gray-600 ${
              isTotal ? "text-gray-700 font-semibold" : ""
            }`}
          >
            {label}
          </p>
          <p
            className={`text-gray-600 ${
              isTotal ? "text-gray-700 font-semibold" : ""
            }`}
          >
            {value}
          </p>
        </div>
      ))}

      <form onSubmit={handleSubmit} className="mt-6">
        <div className="mb-4 flex gap-4 sm:flex-col sm:gap-6">
          {/* Name Input */}
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter Your Name"
            className="w-full sm:w-1/2 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500"
          />

          {/* Card Number Input */}
          <input
            type="text"
            id="cardNumber"
            value={formData.cardNumber}
            onChange={handleChange}
            placeholder="Enter Card Number"
            className="w-full sm:w-1/2 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500"
          />
        </div>

        <div className="mb-4 flex gap-4 sm:flex-col sm:gap-6">
          {/* CVV Input */}
          <input
            type="text"
            id="cvv"
            value={formData.cvv}
            onChange={handleChange}
            placeholder="CVV"
            className="w-full sm:w-1/4 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500"
          />
        </div>

        <button
          type="submit"
          className="w-full sm:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={() => navigate("/leadconfirmation")}
        >
          Pay And Buy
        </button>
      </form>
    </div>
  );
}

export default PayAndBuyLead;
