import React from "react";
import logo from '../../../assets/buyer/image.jpg'
import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";

export function DashboardHeader() {
  return (
    <header className="flex p-5 bg-white px-16 shadow-md sticky top-0 z-[100] items-center justify-between">
      <div className="flex relative items-center gap-5">
        <Link to="/">
          <img
            src={logo}
            className="object-contain scale-[2.3] h-8 w-16"
            alt=""
          />
        </Link>
        <Dropdown />
      </div>
      <div className="flex items-center gap-3">
        <Link to="/">
          <img
            src={logo}
            className="object-contain rounded-full h-8 w-8 border"
            alt=""
          />
        </Link>
        <Link
          to="http://localhost:3000"
          className="font-semibold text-sm bg-blue-400 text-white p-1 px-3 rounded-md hover:cursor-pointer hover:underline"
        >
          Logout
        </Link>
      </div>
    </header>
  );
}
