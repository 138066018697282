import React from "react";
import { qualityLeadsIcon } from "../../../assets/buyer/assets";

const qualityLeadsData = [
  {
    title: "Get quality leads",
    features: [
      "View leads locally or nationwide",
      "Review leads for free",
      "Get leads sent to you in real time",
    ],
  },
  {
    title: "Get quality leads",
    features: [
      "View leads locally or nationwide",
      "Review leads for free",
      "Get leads sent to you in real time",
    ],
  },
  {
    title: "Get quality leads",
    features: [
      "View leads locally or nationwide",
      "Review leads for free",
      "Get leads sent to you in real time",
    ],
  },
];

const QualityLeads = () => {
  return (
    <div className="bg-[#00B2D633] p-6 sm:p-10 md:p-16 flex flex-col items-center">
      <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full max-w-6xl xl:w-[1200px] xl:flex xl:items-center xl:justify-evenly">
        {qualityLeadsData.map((data, index) => (
          <div
            key={index}
            className="flex flex-col items-center sm:items-start gap-4 p-6 border rounded-lg"
          >
            <img
              src={qualityLeadsIcon}
              alt="Quality Leads Icon"
              className="w-12 h-12"
            />
            <h2 className="font-bold text-lg sm:text-xl">{data.title}</h2>
            <ul className="list-disc pl-5 text-sm sm:text-base">
              {data.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
            <button className="mt-4 border border-sky-400 outline-sky-400 text-sm sm:text-base font-bold w-full sm:w-auto px-4 py-2 bg-white hover:text-white rounded-lg hover:bg-gray-800">
              How it works
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualityLeads;
