import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PublicRoute from "./requester/PublicRoute";
import ProtectedRoute from "./requester/ProtectedRoute";
import BuyerPublicRoute from "./buyer/PublicRoute";
import BuyerProtectedRoute from "./buyer/ProtectedRoute";
import { Home, LoginPage } from "../pages/requester";
import { ForgotPassPage } from "../pages/requester/auth/ForgotPassPage";
import Emailverification from "../pages/requester/auth/Emailverification";
import Dashboard from "../pages/requester/home/Dashboard";
import Profile from "../pages/requester/home/Profile";
import Coming from "../pages/requester/Coming";
import {
  Home as BuyerHome,
  LoginPage as BuyerLoginPage,
  RegisterPage,
} from "../pages/buyer";
import LeadsList from "../components/buyer/home/LeadsList";
import LeadDetail from "../pages/buyer/lead/LeadDetail";
import BuyLead from "../components/buyer/home/BuyLead";
import PayAndBuyLead from "../components/buyer/home/PayAndBuyLead";
import Confirmation from "../pages/buyer/lead/Confirmation";
import LeadReview from "../pages/buyer/lead/LeadReview";
import Submit from "../pages/buyer/lead/Submit";
import EmailverificationBuyer from "../components/buyer/auth/EmailVerificationBuyer";
import PhoneVerification from "../components/buyer/auth/PhoneVerification";
import RegisterForm from "../components/buyer/auth/RegisterForm";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coming-soon" element={<Coming />} />
        {/* Public Routes */}
        <Route element={<PublicRoute />}>
          {/* Public Pages */}
          {/* <Route path="/" element={<Home />} /> Default Public Home Page */}
          <Route path="/login" element={<LoginPage />} /> {/* Login Page */}
          <Route path="/login-forgot-password" element={<ForgotPassPage />} />
          <Route
            path="/login-email-verification"
            element={<Emailverification />}
          />
          {/* Login Page */}
        </Route>

        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<Profile />} />
        </Route>

        {/* buyer Routes */}

        {/* Public Routes */}
        <Route path="/leads" element={<BuyerPublicRoute />}>
          {/* Public Pages */}
          <Route path="/leads" element={<BuyerHome />} />{" "}
          {/* Default Public Home Page */}
          <Route path="/leads/login" element={<BuyerLoginPage />} />{" "}
          {/* Login Page */}
          <Route path="/leads/buyeremailverification" element={<EmailverificationBuyer/>}/>
          <Route path="/leads/phoneverification"element={<PhoneVerification/>}/>
          <Route path="/leads/registerbuyer" element={<RegisterForm/>}/>
          <Route path="/leads/register" element={<RegisterPage />} />{" "}
          {/* Login Page */}
        </Route>

        {/* Protected Routes */}
        <Route path="leads/leadslist" element={<LeadsList />} />
        <Route index path="leads/leaddetail/:_id" element={<LeadDetail />} />
        {/* <Route index path="leads/leaddetail/:leadId" element={<LeadDetail />} /> */}
        
        <Route path="/dashboard" element={<BuyerProtectedRoute />}></Route>

        <Route path="leads/buylead" element={<BuyLead />} />
        <Route path="leads/payandbuylead" element={<PayAndBuyLead />} />
        <Route path="leads/leadconfirmation" element={<Confirmation />} />
        <Route path="leads/leadreview" element={<LeadReview />} />
        <Route path="leads/submit" element={<Submit />} />

        {/* Fallback for Undefined Routes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
