import React from "react";
import logo from "../../../assets/requester/image.jpg";

import { DashboardHeader } from "../../../components/requester/dashboard/DashboardHeader";
import Profilecard from "../../../components/requester/profile/Profilecard";

function Profile() {
  return (
    <div className="min-h-screen font-Rubik bg-dark-background-neww">
      <DashboardHeader />
      <div className="h-80 bg-light-background-neww"></div>
      <div className="inset-0 z-[50] h-screen justify-center flex items-center fixed">
        <div className="relative flex justify-center">
          <Profilecard />
          <img
            src={logo}
            alt=""
            className="h-24 w-24 -top-7  border rounded-full shadow-md absolute"
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;
