import { DialogPanel } from "@headlessui/react";
import React from "react";
import { PulseLoader } from "react-spinners";
import Window1 from "./windows/Window1";
import Window2 from "./windows/Window2";
import Window3 from "./windows/Window3";
import Window4 from "./windows/Window4";
import Window5 from "./windows/Window5";
import Window6 from "./windows/Window6";
import Window7 from "./windows/Window7";
import Window8 from "./windows/Window8";
import Window9 from "./windows/Window9";
import Window10 from "./windows/Window10";
import PropTypes from "prop-types";

Windows.propTypes = {
  properties: PropTypes.object.isRequired,
};

function Windows({ properties }) {
  const {
    num,
    dispatch,
    startDate,
    value,
    people,
    textValue,
    handleChange,
    error,
    requestor,
    emailverified,
    phone,
    phonecode,
    phoneverified,
    pherror,
    handle6,
    email,
    terms,
    referemail,
    refername,
    referphone,
    cancel,
    loading,
    handlenext,
    hidebottom,
  } = properties;
  return (
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex min-h-full justify-center p-4 text-center items-center sm:p-0"
      >
        <DialogPanel
          transition
          className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
        >
          {num === 1 && (
            <Window1
              cancel={cancel}
              dispatch={dispatch}
              startDate={startDate}
            />
          )}
          {num === 2 && (
            <Window2
              cancel={cancel}
              num={num}
              value={value}
              dispatch={dispatch}
              people={people}
            />
          )}
          {num === 3 && (
            <Window3
              cancel={cancel}
              dispatch={dispatch}
              num={num}
              textValue={textValue}
              error={error}
              handleChange={handleChange}
            />
          )}

          {num === 4 && (
            <Window4
              cancel={cancel}
              loading={loading}
              num={num}
              email={email}
              dispatch={dispatch}
            />
          )}
          {num === 5 && (
            <Window5
              cancel={cancel}
              requestor={requestor}
              emailverified={emailverified}
              dispatch={dispatch}
              num={num}
            />
          )}
          {num === 6 && (
            <Window6
              cancel={cancel}
              num={num}
              phonecode={phonecode}
              phone={phone}
              pherror={pherror}
              dispatch={dispatch}
              handle6={handle6}
            />
          )}
          {num === 7 && (
            <Window7
              handle6={handle6}
              cancel={cancel}
              num={num}
              dispatch={dispatch}
              phoneverified={phoneverified}
              requestor={requestor}
            />
          )}
          {num === 8 && (
            <Window8
              cancel={cancel}
              phoneverified={phoneverified}
              num={num}
              dispatch={dispatch}
            />
          )}
          {num === 9 && (
            <Window9
              cancel={cancel}
              num={num}
              dispatch={dispatch}
              referemail={referemail}
              refername={refername}
              referphone={referphone}
            />
          )}
          {num === 10 && (
            <Window10
              cancel={cancel}
              num={num}
              refer={refername && referemail && referphone}
              terms={terms}
              dispatch={dispatch}
            />
          )}
          {!hidebottom && (
            <div className=" mb-3 py-3 flex flex-row-reverse px-6">
              <button
                disabled={loading}
                type="submit"
                onClick={() => {
                  handlenext();
                }}
                className={`inline-flex ${
                  num === 5 && !emailverified && "bg-blue-400"
                }  justify-center disabled:cursor-not-allowed w-full rounded-md bg-light-background-neww px-3 py-3 font-semibold text-white shadow-sm  ml-3 `}
              >
                {loading ? (
                  <PulseLoader color="#f6f6f6" />
                ) : num < 8 ? (
                  "Next"
                ) : (
                  "Create Lead"
                )}
              </button>
              {/* <button
                disabled={loading}
                type="button"
                onClick={() => cancel()}
                className="inline-flex  justify-center disabled:cursor-not-allowed rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ml-3 w-auto"
              >
                Cancel
              </button> */}
            </div>
          )}
        </DialogPanel>
      </form>
    </div>
  );
}

export default Windows;
