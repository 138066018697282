import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";

AccountBlockWindow.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

function AccountBlockWindow({ setOpen }) {
  return (
    <div className="bg-white border w-full min-h-[300px] px-4 pb-4 animate-fade pt-10 sm:p-6 sm:pb-4">
      <div className="sm:flex w-full h-full sm:items-start">
        <div className="mt-3 w-full h-full text-center">
          <DialogTitle
            as="h3"
            className="text-2xl w-full text-center font-semibold text-gray-600"
          >
            Account Blocked
          </DialogTitle>
          <p className="mt-5 text-slate-700">Your account is blocked!</p>
          <div className="mt-2 w-full flex items-center justify-center">
            <div className="flex mt-5 items-center gap-5">
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="bg-red-300 rounded-md p-3 px-5  text-red-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountBlockWindow;
