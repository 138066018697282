import React from "react";
import "./index.css";
import Router from "./routers/Router";
import { SnackbarProvider } from "notistack";
import {
  ThemeContext,
  ThemeContextProvider,
} from "./theme/ThemeContextProvider";
import toast, { Toaster } from "react-hot-toast";

function App() {
  const snackbarProvider = React.useRef();

  return (
    <ThemeContextProvider>
      <SnackbarProvider
        ref={snackbarProvider}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        maxSnack={3}
        action={(snackbarId) => (
          <button
            size="small"
            color="inherit"
            onClick={() => snackbarProvider.current.closeSnackbar(snackbarId)}
          >
            Dismiss
          </button>
        )}
      >
        <Router />
      </SnackbarProvider>
      {/* <Router /> */}
      <Toaster
        toastOptions={{
          style: {
            border: "1px solid",
            padding: "10px",
          },
        }}
      />
    </ThemeContextProvider>
  );
}

export default App;
