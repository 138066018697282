import React from "react";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import LoginEmailverifyForm from "./LoginEmailverify";
import AccountBlockModal from "../modal/AccountBlockModal";
import useLogin from "../../../hooks/requester/useLogin";

function LoginForm() {
  const {
    email,
    accountblocked,
    onpass,
    loading,
    otp,
    password,
    requesterId,
    setaccountBlocked,
    setEmail,
    setOn,
    setPassword,
    submit,
  } = useLogin();

  if (otp)
    return <LoginEmailverifyForm submit={submit} requesterId={requesterId} />;
  return (
    <form className="h-fit min-h-[340px] font-Rubik font-semibold p-5 rounded-md border mt-3 w-[500px] bg-[#fcfcff]">
      <p className="text-center text-4xl mt-5 font-semibold text-slate-700">
        Login
      </p>
      <div className="flex mt-3 flex-col gap-5">
        <div className="flex flex-col gap-1">
          <label className="text-lg">Email</label>
          <input
            autoFocus
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            type="email"
            className="font-normal border-b placeholder:font-normal placeholder:text-sm border-stone-600 bg-transparent outline-none p-2"
          />
        </div>
        {!onpass && (
          <p
            onClick={() => {
              setOn((onpass) => !onpass);
            }}
            className="text-xs text-blue-500 hover:cursor-pointer font-normal text-end underline"
          >
            Login with email and password?
          </p>
        )}
        {onpass && (
          <div className="flex animate-fade-down flex-col gap-1">
            <label className="text-lg flex justify-between items-center">
              <p>Password</p>
              <Link
                to="/login-forgot-password"
                className="text-slate-500 text-sm"
              >
                Forgot Password?
              </Link>
            </label>
            <input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
              placeholder="Enter your password"
              type="password"
              className=" border-b font-normal placeholder:font-normal placeholder:text-sm border-stone-600 bg-transparent outline-none p-2"
            />
          </div>
        )}
        {onpass && (
          <p
            onClick={() => {
              setOn((onpass) => !onpass);
            }}
            className="text-xs text-blue-500 hover:cursor-pointer font-normal text-end underline"
          >
            Login with email and Otp?
          </p>
        )}
        <button
          disabled={loading}
          onClick={submit}
          className="bg-light-background-neww mt-5 border active:bg-light-background-accent font-semibold text-white p-2 py-3 rounded-md text-lg"
        >
          {loading ? <PulseLoader color="#f6f6f6" /> : "Submit"}
        </button>
      </div>
      <AccountBlockModal open={accountblocked} setOpen={setaccountBlocked} />
    </form>
  );
}

export default LoginForm;
