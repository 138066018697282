import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Tableloader from "./Tableloader";
import { FaSort } from "react-icons/fa";
import PropTypes from "prop-types";
import CloseReqModal from "../modal/CloseReqModal";

const SortableTable = ({
  loading,
  tableData,
  columns = [
    { key: "requestId", label: "Request ID" },
    { key: "category", label: "Category" },
    { key: "notes", label: "Request Notes" },
    { key: "date", label: "Service Date" },
    { key: "value", label: "Service Value" },
    { key: "limit", label: "Limit" },
    { key: "received", label: "Received" },
    { key: "limit", label: "Limit" },
    { key: "total", label: "Total Earnings" },
  ],
}) => {
  const [data, setData] = useState(tableData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    setData(tableData);
    setCurrentPage(1);
  }, [tableData]);

  const sortData = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sortableData = [...data];
    const currentData = sortableData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    const sortedData = [...currentData].sort((a, b) => {
      if (typeof a[key] === "number") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      if (Object.prototype.toString.call(a[key]) === "[object Date]") {
        return direction === "asc"
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      }
      return direction === "asc"
        ? a[key].localeCompare(b[key])
        : b[key].localeCompare(a[key]);
    });

    const updatedData = [
      ...data.slice(0, (currentPage - 1) * itemsPerPage),
      ...sortedData,
      ...data.slice(currentPage * itemsPerPage),
    ];

    setData(updatedData);
    setSortConfig({ key, direction });
  };
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-gray-700 text-[12px] bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className="px-6 py-5 cursor-pointer"
                onClick={() => sortData(column.key)}
              >
                <div className="flex gap-1 items-center">
                  {column.label}
                  <FaSort className="h-[10px] w-[10px]" />
                </div>
              </th>
            ))}
            <th className="px-6 py-3">Request Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Tableloader length={columns.length} />
          ) : paginatedData.length > 0 ? (
            paginatedData.map((item, index) => (
              <tr
                key={index}
                className="bg-white border-b text-[13px] dark:bg-gray-800 dark:border-gray-700"
              >
                {columns.map((column) => (
                  <td key={column.key} className="px-6 py-4">
                    {column.key === "serviceDate"
                      ? format(item[column.key], "dd/MM/yyyy")
                      : column.key === "possibleEarnings" ||
                        column.key === "earnings"
                      ? parseFloat(item[column.key]).toFixed(2)
                      : item[column.key]}
                  </td>
                ))}
                <td className="px-6 py-4">
                  <Link
                    onClick={() => {
                      setCloseModal(true);
                    }}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Close Request
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length + 1}
                className="px-6 py-4 text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data.length > 0 && (
        <div className="flex justify-between p-2 pb-4 px-8 items-center mt-1">
          <span className="text-sm font-semibold text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <div className="flex items-center gap-2">
            <button
              className="rounded-md border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            <button
              className="rounded-md border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <CloseReqModal open={closeModal} setOpen={setCloseModal} />
    </div>
  );
};

export default SortableTable;

SortableTable.propTypes = {
  loading: PropTypes.bool,
  tableData: PropTypes.array,
  columns: PropTypes.array,
};
