import React from "react";
import Example from "../modal/Modalwindow";
import AcceptModal from "../modal/AcceptModal";
import AccountBlockModal from "../modal/AccountBlockModal";
import { IoLocationSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { useHome } from "../../../hooks/requester/useHome";

const dist = ["1", "5", "10", "20", "30", "50", "100", "150"];

function Main() {
  const {
    err,
    modalState,
    setmodalState,
    handleSearch,
    filteredServices,
    handleOpen,
    searchTerm,
    setSearchTerm,
    selectService,
    setSelectService,
    setFilteredServices,
    setPincode,
    pincode,
    setErr,
    setKm,
    km,
  } = useHome();

  return (
    <div className="h-[320px] bg-white z-10 ">
      <div className="flex mb-32 flex-col">
        <div className="flex px-5 xl:px-40 ml-5 xl:ml-40 mt-20 items-center gap-3">
          <p className="bg-light-background-neww w-16 md:w-28 h-[3px]"></p>
          <p className="text-light-text-neww text-xs sm:text-sm md:text-base font-semibold">
            Post Your Request & Get paid!
          </p>
        </div>

        <div className="flex mt-6 sm:mt-10 items-center justify-center">
          <div className="animate-fade-up flex flex-col items-center gap-5 animate-delay-[100ms]">
            <div className="px-2 flex flex-col md:flex-row mt-7 md:mt-[25px] sm:gap-3 gap-2 items-center">
              <div className="relative  flex w-full justify-center gap-2 items-center h-full">
                <p className="text-sm sm:text-base">For my</p>
                <div className="relative flex  items-center h-full">
                  <select
                    value="Personal"
                    onChange={(e) => {
                      if (e.target.value === "Bussiness") {
                        window.location.href = "https://savvymp.com/";
                      }
                      // setCity(e.target.value);
                    }}
                    className="md:w-[200px] w-[120px] h-12 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-light-background-neww rounded-lg pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                  >
                    <option value="Personal">Personal</option>
                    <option value="Bussiness">Bussiness</option>
                  </select>
                  <IoMdArrowDropdown className="h-6 w-6 absolute -z-10 right-3 " />
                </div>
                <p className="text-sm sm:text-base">use, I need</p>
              </div>
              <div className="relative">
                {/* Search Input */}
                <div className="relative">
                  <input
                    type="text"
                    onFocus={() => {
                      setErr("");
                    }}
                    className={`shadow-sm px-4 py-2 border w-[300px] sm:w-[350px] h-12 text-center rounded-lg text-sm  ${
                      err && !searchTerm
                        ? "placeholder:text-red-500 border-red-400"
                        : "placeholder:text-slate-400 border-light-background-neww"
                    } focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300`}
                    placeholder={
                      err
                        ? "Please select a service"
                        : "Search for a service..."
                    }
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>

                {/* Dropdown List */}
                {filteredServices.length > 0 && (
                  <ul className="absolute left-0 right-0 mt-1 bg-white border border-slate-300 rounded-lg max-h-60 overflow-auto shadow-lg z-10">
                    {filteredServices.map((service, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                        onClick={() => {
                          setSearchTerm(service.category);
                          setSelectService(service);
                          setFilteredServices([]);
                        }}
                      >
                        {service.category}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <AccountBlockModal
                open={modalState.accountblocked}
                setOpen={(state) => {
                  setmodalState((modalState) => {
                    return { ...modalState, accountblocked: state };
                  });
                }}
              />
              <AcceptModal
                open={modalState.acceptopen}
                setOpen={(state) => {
                  setmodalState((modalState) => {
                    return { ...modalState, acceptopen: state };
                  });
                }}
              />
              <Example
                open={modalState.open}
                pincode={pincode}
                km={km}
                selectService={selectService}
                setOpen={(state) => {
                  setmodalState((modalState) => {
                    return { ...modalState, open: state };
                  });
                }}
              />
            </div>
            <div className="h-12 flex items-center gap-1 sm:gap-3">
              <p className="text-sm sm:text-base">With in</p>
              <div className="relative flex items-center h-full">
                <IoLocationSharp className="absolute w-5 text-light-background-neww h-5 left-3" />
                <select
                  onChange={(e) => {
                    setKm(e.target.value);
                  }}
                  className="sm:w-28 w-24 pl-8 h-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-light-background-neww rounded pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  {dist.map((obj, i) => {
                    return (
                      <option key={i} value={obj}>
                        {obj} KM
                      </option>
                    );
                  })}
                </select>
                <IoMdArrowDropdown className="h-6 w-6 absolute -z-10 right-2 md:right-2 " />
              </div>
              <p className="text-sm sm:text-base">From</p>
              <div className="relative">
                <input
                  type="text"
                  className={`${
                    !pincode
                      ? "placeholder:text-red-500 border-red-400 text-xs"
                      : "placeholder:text-slate-400 border-light-background-neww"
                  } uppercase text-center shadow-sm px-4 py-2 pt-5 border w-28 sm:w-32  h-12  rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300`}
                  value={pincode}
                  placeholder={"required"}
                  onChange={(e) => {
                    setPincode(e.target.value);
                  }}
                />
                <p className="text-[12px] absolute top-1 left-6 sm:left-8">
                  Postal Code
                </p>
              </div>
            </div>
            <button
              onClick={() => handleOpen()}
              className="p-[7px]  bg-light-background-neww md:mt-4 ring-2 shadow-md shadow-black ring-white md:w-[400px] w-[300px] sm:w-[350px] rounded-md border text-white px-5 font-semibold"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
