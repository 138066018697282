import { useInView } from "@react-spring/web";

export function useDiscover() {
  const sections = [
    useInView({ once: true }),
    useInView({ once: true }),
    useInView({ once: true }),
    useInView({ once: true }),
  ];

  const [ref1, inView1] = sections[0];
  const [ref2, inView2] = sections[1];
  const [ref3, inView3] = sections[2];
  const [ref4, inView4] = sections[3];

  return { ref1, inView1, ref2, inView2, ref3, inView3, ref4, inView4 };
}
