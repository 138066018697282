export const initialstate = {
  people: 1,
  value: "",
  num: 1,
  email: "",
  terms: false,
  startDate: new Date(),
  emailverified: false,
  phoneverified: false,
  textValue: "",
  error: "",
  requestor: {},
  phone: "",
  phonecode: "",
  pherror: "",
  refername: "",
  referemail: "",
  referphone: "",
  refer: false,
  loading: false,
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case "set-num":
      return { ...state, num: payload };
    case "set-req":
      return { ...state, requestor: payload };

    case "set-pherr":
      return { ...state, pherror: payload };
    case "set-phone":
      return { ...state, phone: payload };

    case "set-error":
      return { ...state, error: payload };

    case "set-text":
      return { ...state, textValue: payload };
    case "set-value":
      return { ...state, value: payload };

    case "set-email":
      return { ...state, email: payload };

    case "set-people":
      return { ...state, people: payload };

    case "set-terms":
      return { ...state, terms: payload };

    case "set-date":
      return { ...state, startDate: payload };

    case "set-email-veri":
      return { ...state, emailverified: payload, num: 6 };

    case "set-phone-veri":
      return { ...state, phoneverified: payload, num: 8 };

    case "set-phone-code":
      return { ...state, phonecode: payload };

    case "set-refer":
      return { ...state, refer: true, num: 9 };

    case "set-no-refer":
      return { ...state, refer: false, num: 10 };

    case "set-ref-name":
      return { ...state, refername: payload };

    case "set-ref-email":
      return { ...state, referemail: payload };

    case "set-ref-phone":
      return { ...state, referphone: payload };

    case "req-id":
      return { ...state, requestor: { requesterId: payload }, num: 5 };

    case "set-loading":
      return { ...state, loading: true };

    case "stop-loading":
      return { ...state, loading: false };

    case "set-cancel":
      return initialstate;

    default:
      return state;
  }
}
