import React from "react";
import { FaChevronRight, FaQuoteLeft, FaChevronLeft } from "react-icons/fa";
import { avatar } from "../../../assets/buyer/assets";

function CustomerFeedback() {
  return (
    <div className="flex flex-col gap-6 bg-[#00B2D6] px-6 py-6 sm:px-8 sm:py-8 md:px-10 md:py-10 lg:px-16 lg:py-16 font-Poppins">
      <div className="flex flex-col md:flex-col lg:flex-row justify-between items-center text-center md:text-left">
        <h1 className="text-[24px] sm:text-[30px] md:text-[40px] font-bold text-white">
          What our customers say
        </h1>
        <p className="text-white font-light text-sm sm:text-base md:text-lg mt-4 md:mt-0">
          Rated 4.7 / 5 based on 28,370 reviews Showing our 4 & 5 star reviews
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-center gap-6">
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className="flex flex-col gap-4 w-full lg:w-[30%] rounded-[25px] py-4 px-6 bg-white shadow-lg"
            >
              <div className="flex justify-between items-center">
                <h2 className="text-[16px] sm:text-[18px] md:text-[20px] font-medium">
                  Great Work
                </h2>
                <FaQuoteLeft className="text-[#00B2D6] w-6 sm:w-8 text-[40px]" />
              </div>
              <p className="text-sm sm:text-base">
                “Amazing design, easy to customize and a design quality
                superlative account on its cloud platform for the optimized
                performance. And we didn’t on our original designs.”
              </p>
              <div className="flex items-center gap-4">
                <img src={avatar} alt="" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="text-[14px] sm:text-[16px] font-medium">
                    Leslie Alexander
                  </p>
                  <p className="text-[12px] sm:text-[14px]">Facebook</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-center gap-4 mt-6">
        <button className="flex items-center justify-center h-10 w-10 rounded-[16px] bg-white text-[#00B2D6] shadow-md">
          <FaChevronLeft />
        </button>
        <button className="flex items-center justify-center h-10 w-10 rounded-[16px] bg-white text-[#00B2D6] shadow-md">
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
}

export default CustomerFeedback;
