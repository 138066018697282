import { useState } from "react";
import toast from "react-hot-toast";
import { $crud } from "../../utils/CrudFactory";

export default function useLogin() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [onpass, setOn] = useState(false);
  const [requesterId, setReqId] = useState("");
  const [accountblocked, setaccountBlocked] = useState(false);

  async function submit(e) {
    e?.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isPasswordValid = password && password.length >= 8;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (onpass && !isPasswordValid) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }
    const datas = onpass ? { email, password } : { email };
    setLoading(true);
    try {
      const { data } = await $crud.post("requester/login", datas);
      setReqId(data.requesterId);
      setOtp(true);
    } catch (error) {
      setReqId("");
      setOtp(false);
    } finally {
      setLoading(false);
    }
  }

  return {
    email,
    otp,
    password,
    loading,
    onpass,
    requesterId,
    accountblocked,
    setEmail,
    setaccountBlocked,
    setLoading,
    setOtp,
    setOn,
    setReqId,
    setPassword,
    submit,
  };
}
