import { DialogTitle } from "@headlessui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { $crud } from "../../../../utils/CrudFactory";
import { PulseLoader } from "react-spinners";

AccountDeleteWindow.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func,
  setOpen: PropTypes.func,
  setNum: PropTypes.func,
};

function AccountDeleteWindow({ email, setOpen, setNum, setEmail }) {
  const [loading, setLoading] = useState(false);
  async function handleSentOTP() {
    try {
      setLoading(true);
      await $crud.post("requester/send-otp ", { email });
      setNum(2);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="bg-white border w-full pb-4 pt-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="mt-3 w-full sm:mt-0 sm:text-left">
          <DialogTitle
            as="h3"
            className="text-2xl border-b pb-4 w-full text-center font-semibold text-gray-700"
          >
            Delete Account
          </DialogTitle>
          <div className="px-6 animate-fade">
            <p className="mt-10 text-center text-lg"> Your Email</p>
            <p className="text-center text-xs text-stone-500">
              We will sent a otp to verify
            </p>
            <p className="mt-3 ">Enter your email</p>
            <div className="mt-2  w-full">
              <input
                type="email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="outline-none w-full rounded-md p-2 px-4 border-2"
              />
            </div>
            <button
              className="font-semibold text-white text-center w-full p-3 rounded-md mt-3 bg-red-500"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSentOTP}
              className="font-semibold text-white text-center w-full p-3 rounded-md mt-3 bg-light-background-neww"
            >
              {loading ? <PulseLoader color="#f6f6f6" /> : "Sent OTP"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDeleteWindow;
