import React from "react";
import PropTypes from "prop-types";
import { animated, useInView } from "@react-spring/web";

ServiceRow.propTypes = {
  posts: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

function ServiceRow({ posts, title }) {
  const [ref, inView] = useInView({ once: true });
  return (
    <animated.div
      ref={ref}
      className={`${
        inView && "translate-x-0"
      } -translate-x-full transition-transform duration-[700ms]`}
    >
      <p className="font-semibold md:text-start text-center md:px-0 lg:ml-24 xl:ml-0 md:ml-16 sm:ml-0 px-6 text-xl">
        {title}
      </p>
      <div
        className={` mx-auto flex flex-wrap justify-center gap-x-6 gap-y-16 pt-3 lg:mx-0 lg:max-w-none`}
      >
        {posts.map((post) => (
          <div
            className="rounded-lg px-3 w-[290px] p-3 hover:scale-[1.03] transition-all duration-300 shadow-lg h-[215px]"
            key={post.id}
          >
            <img
              src={post.imageUrl}
              className="w-full rounded-lg h-36 object-cover"
              alt=""
            />
            <p className="p-2 px-3 mt-2 text-center bg-light-background-neww rounded-md  font-semibold text-white">
              {post.title}
            </p>
          </div>
        ))}
      </div>
    </animated.div>
  );
}

export default ServiceRow;
