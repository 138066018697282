import toast from "react-hot-toast";
import { $crud } from "../../utils/CrudFactory";

export async function updatephone(
  verifyPhone,
  requestor,
  phonecode,
  phone,
  dispatch
) {
  const datas = {
    requesterId: requestor.requesterId,
    phone: phone
      ? Number(phonecode.slice(1, phonecode.length) + String(phone))
      : Number(JSON.parse(localStorage.getItem("requester")).phone),
    verifyPhone,
  };
  try {
    const phoneRegex = /\b\d{10,15}\b/;
    if (phone) {
      if (!phoneRegex.test(phone)) {
        toast.error("Please give valid phone number!");
        return;
      }
    }
    await $crud.put("requester/update-phone", datas);
    if (verifyPhone) {
      dispatch({ type: "set-num", payload: 7 });
    } else {
      dispatch({ type: "set-num", payload: 8 });
    }
  } catch (e) {
    console.error(e);
  }
}
