import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window4.propTypes = {
  dispatch: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  num: PropTypes.number,
  cancel: PropTypes.func,
};

function Window4({ dispatch, loading, cancel, num, email }) {
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className=" relative w-full sm:mt-0 sm:text-left">
          <button
            disabled={loading}
            onClick={cancel}
            className="absolute disabled:cursor-not-allowed right-4 top-5"
          >
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            disabled={loading}
            className="absolute top-5 disabled:cursor-not-allowed left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-6 animate-fade">
            <p className="mt-14 text-center font-semibold text-lg">
              Email Verification
            </p>
            <p className="text-center text-xs text-stone-500">
              We will send you a verfication code to verify your email
            </p>

            <div className="mt-2 flex justify-center items-center w-full">
              <div className="w-full md:w-96">
                {/* <p className="mt-3 ">Enter your email</p> */}
                <input
                  type="email"
                  required
                  disabled={loading}
                  placeholder="Enter your email..."
                  value={email}
                  onChange={(e) => {
                    dispatch({
                      type: "set-email",
                      payload: e.target.value,
                    });
                  }}
                  className="outline-none w-full mt-5 disabled:cursor-not-allowed rounded-xl p-2 px-4 border-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window4;
