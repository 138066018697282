//LOCAL

// export const buyerurl = "http://localhost:3001";

// export const BASE_URL =
//   "https://05e9-2405-201-6800-d14e-b931-c255-9011-17e5.ngrok-free.app";

// export const API_URL =
//   "https://05e9-2405-201-6800-d14e-b931-c255-9011-17e5.ngrok-free.app/api/";

// DEV

export const buyerurl = "http://localhost:3001";

export const BASE_URL = "https://apidev.pqckle.com";

export const API_URL = "https://apidev.pqckle.com/api/";

// Other

export const countryCode_api_url = "https://api.country.is/";
export const currency_api_url = "https://restcountries.com/v3.1/alpha";
export const postcode_api_url = "https://nominatim.openstreetmap.org";
