import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { $crud } from "../../../utils/CrudFactory";

const SortableTable = ({
  columns = [
    { key: "_id", label: "Lead ID" },
    { key: "service.category", label: "Category" },
    { key: "distance", label: "Distance (km)" },
    { key: "serviceNotes", label: "Service Notes" },
    { key: "pincode", label: "Pincode" },
    { key: "createdAt", label: "Created At" },
    { key: "leadSalePrice", label: "Sale Price" },
    { key: "requester.email", label: "Requester Email" },
    { key: "requester.phone", label: "Requester Phone" },
  ],
}) => {
  const getValueFromPath = (obj, path) => {
    return path.split(".").reduce((acc, key) => (acc ? acc[key] : null), obj);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("category");
  const searchDistance = queryParams.get("distance");

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Fetch data from API
  async function retrieveData() {
    try {
      const url = `/buyer/leads?category=${searchValue}&distance=${searchDistance}`;
      const response = await $crud.retrieve(url);
      setData(response.data?.leads || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Fallback to an empty array if an error occurs
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    retrieveData();
  }, [searchValue, searchDistance]);

  const sortData = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sortedData = [...data].sort((a, b) => {
      if (typeof a[key] === "number") {
        return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      return direction === "asc"
        ? a[key]?.localeCompare(b[key] || "")
        : b[key]?.localeCompare(a[key] || "");
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (isLoading) {
    return <div>Loading data...</div>;
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className="px-6 py-5 cursor-pointer"
                onClick={() => sortData(column.key)}
              >
                {column.label}
              </th>
            ))}
            <th className="px-6 py-3">Lead Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            paginatedData.map((item) => (
              <tr
                key={item._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                {columns.map((column) => (
                  <td key={column.key} className="px-6 py-4">
                    {getValueFromPath(item, column.key) || "N/A"}
                  </td>
                ))}
                <td className="px-6 py-4 text-center">
                  <Link
                    to={`/leads/leaddetail/${item._id}`}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    View Details
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="px-6 py-4 text-center">
                No leads found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data.length > 0 && (
        <div className="flex justify-between p-2 pb-4 px-8 items-center mt-4">
          <span className="text-sm font-semibold text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <div className="flex items-center gap-2">
            <button
              className="rounded-md p-2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="rounded-md p-2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortableTable;