import React, { useState } from "react";
import { $crud } from "../../../utils/CrudFactory";
import ResetPassword from "./ResetPassword";

function ForgotPassForm() {
  const [reset, setReset] = useState(false);
  const [reqId, setReqId] = useState("");
  const [email, setEmail] = useState("");

  async function sentOtp() {
    try {
      const { data } = await $crud.post("requester/send-otp ", { email });
      setReqId(data.requesterId);
      setReset(true);
    } catch (e) {
      setReqId("");
      setReset(false);
    }
  }
  if (reset) return <ResetPassword requesterId={reqId} />;
  return (
    <div className="h-96 font-semibold p-5 font-Rubik rounded-md border mt-3 w-[500px] bg-slate-50">
      <p className="text-center text-4xl mt-5 font-semibold text-slate-700">
        Forgot Password
      </p>
      <p className="text-center text-sm mt-5 text-stone-500">
        Enter your email and we will sent you a link to reset your password!
      </p>
      <div className="flex mt-5 flex-col gap-5">
        <div className="flex flex-col gap-1">
          <label className="text-lg">Email</label>
          <input
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="rounded-md shadow-sm border outline-none p-2"
          />
        </div>
        <button
          onClick={sentOtp}
          className="bg-[#1d198a] disabled:cursor-not-allowed mt-10 border active:bg-blue-900 font-semibold text-white p-2 py-3 rounded-md text-lg"
        >
          Sent
        </button>
      </div>
    </div>
  );
}

export default ForgotPassForm;
