import { useState } from "react";

const Input = ({ label, id, type, placeholder, value, onChange, required }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full p-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      required={required}
    />
  </div>
);

const Select = ({ label, id, value, onChange, options, required }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <select
      id={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full p-3 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      required={required}
    >
      {options.map((option) => (
        <option key={option.code} value={option.code}>
          {option.country} (+{option.code})
        </option>
      ))}
    </select>
  </div>
);

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    registrantName: "",
    phoneCountryCode: "+1", // Default country code
    phoneNumber: "",
    email: "",
    companyName: "",
    yearsInBusiness: "",
    accreditations: "",
    certifications: "",
    designations: "",
    industryStandards: "",
    bbb: "",
    vive: "",
    homestars: "",
    savvyReviews: "",
    insurance: "",
    awards: "",
    socialProfiles: "",
  });

  const handleChange = ({ target: { id, value } }) =>
    setFormData((prev) => ({ ...prev, [id]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const phoneCountryOptions = [
    { country: "United States", code: "1" },
    { country: "Canada", code: "1" },
    { country: "United Kingdom", code: "44" },
    { country: "Australia", code: "61" },
    { country: "India", code: "91" },
    // Add more countries as needed
  ];

  const inputs = [
    {
      label: "Registrant Name",
      id: "registrantName",
      type: "text",
      required: true,
    },
    { label: "Email", id: "email", type: "email", required: true },
    { label: "Company Name", id: "companyName", type: "text", required: true },
  ];

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
        <h2 className="text-center text-4xl rounded-md font-semibold text-white bg-[#00B2D6] p-4 mb-6">
          Register
        </h2>
        <form onSubmit={handleSubmit}>
          {inputs.map(({ label, id, type, placeholder, required }) => (
            <Input
              key={id}
              label={label}
              id={id}
              type={type}
              placeholder={placeholder}
              value={formData[id]}
              onChange={handleChange}
              required={required}
            />
          ))}

          {/* Phone Number with Country Code */}
          <div className="mb-4 flex flex-col md:flex-row md:space-x-4">
            <Select
              label="Phone Country Code"
              id="phoneCountryCode"
              value={formData.phoneCountryCode}
              onChange={handleChange}
              options={phoneCountryOptions}
              required
            />
            <Input
              label="Phone Number"
              id="phoneNumber"
              type="tel"
              placeholder="Enter your phone number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-[#00B2D6] hover:bg-blue-600 text-white font-bold py-3 rounded-md mt-6 transition duration-200"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;
