import React, { useEffect, useState } from "react";
import { DashboardHeader } from "../../../components/requester/dashboard/DashboardHeader";
import SortableTable from "../../../components/requester/dashboard/SortableTable";
import { retrieveData } from "../../../helper/requester/functions";
import DashboardNav from "./DashboardNav";
import DeletedAccountModal from "../../../components/requester/modal/DeletedAccountModal";
import Modalwindow from "../../../components/requester/modal/Modalwindow";

const columnsByMode = {
  requester: [
    { key: "leadId", label: "Lead ID" },
    { key: "createdAt", label: "Created At" },
    { key: "serviceCategory", label: "Service Category" },
    { key: "serviceNotes", label: "Request Notes" },
    { key: "serviceDate", label: "Service Date" },
    { key: "value", label: "Amount" },
    { key: "limit", label: "Limit" },
    { key: "bought", label: "Bought" },
    { key: "possibleEarnings", label: "Possible Earnings" },
    { key: "reqStatus", label: "Request Status" },
    { key: "referredBy", label: "Referred By" },
  ],
  referral: [
    { key: "leadId", label: "Lead ID" },
    { key: "createdAt", label: "Created At" },
    { key: "serviceCategory", label: "Service Category" },
    { key: "serviceNotes", label: "Request Notes" },
    { key: "serviceDate", label: "Service Date" },
    { key: "value", label: "Amount" },
    { key: "limit", label: "Limit" },
    { key: "earnings", label: "Earnings" },
    { key: "commissionStatus", label: "Commission Status" },
    { key: "reqStatus", label: "Request Status" },
    { key: "requesterName", label: "Requester You Referred" },
  ],
};

function Dashboard() {
  const [open, setOpen] = useState(false);
  const [viewMode, setviewMode] = useState(() => {
    return JSON.parse(localStorage.getItem("isSelfLead"));
  });
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(true);
  const req = JSON.parse(localStorage.getItem("requester"));

  const columns =
    columnsByMode[viewMode || viewMode === null ? "requester" : "referral"];
  useEffect(() => {
    async function fetchdata() {
      setloading(true);
      try {
        if (!viewMode && viewMode !== null) {
          const data = await retrieveData(
            `requester/leads-referral?referrerId=${req._id}`
          );
          setdata(data.leads);
        } else {
          const data = await retrieveData(
            `requester/leads-requester?requesterId=${req._id}`
          );
          setdata(data.leads);
        }
      } catch (e) {
        setdata([]);
      } finally {
        setloading(false);
      }
    }
    fetchdata();
  }, [req.referrerId, viewMode, req._id]);

  const active = viewMode || viewMode === null ? true : false;
  const deleteAccount = req?.deleteAccount || false;

  return (
    <div className="min-h-screen">
      <DashboardHeader setOpen={setOpen} />
      <div className="p-7">
        <DashboardNav active={active} setviewMode={setviewMode} />
        <SortableTable loading={loading} tableData={data} columns={columns} />
      </div>
      <DeletedAccountModal open={deleteAccount} />
      <Modalwindow open={open} setOpen={setOpen} />
    </div>
  );
}

export default Dashboard;
