import React from "react";
import { useInView, animated } from "@react-spring/web";
import { HiDocumentPlus } from "react-icons/hi2";
import { IoDocumentTextOutline } from "react-icons/io5";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

function DescribeSteps() {
  const [ref, InView] = useInView({
    once: true,
  });
  return (
    <>
      <animated.div
        ref={ref}
        className={`flex mt-[70px] md:mt-[130px] ${
          InView && "animate-fade scale-100 animate-duration-[1.8s]"
        } justify-center w-full scale-0 transition-transform duration-[500ms] items-center`}
      >
        <div className="flex flex-col text-center gap-2 items-center">
          <p className="text-dark-background-secondary font-bold text-xl md:text-4xl">
            Alone We Strive, Together We Thrive!
          </p>
          <p className="text-light-background-neww font-bold text-xl md:text-4xl">
            Find, Compare, Connect – It’s That Easy!
          </p>
        </div>
      </animated.div>
      <animated.div
        ref={ref}
        className={`flex lg:flex-row flex-col flex-wrap mt-10 md:mt-20 gap-12 xl:gap-1  ${
          InView && "animate-fade animate-duration-[1.8s]"
        }  justify-center w-full items-center`}
      >
        <div className="flex  max-w-72 flex-col items-center justify-center gap-5">
          <p className="rounded-full flex items-center justify-center w-20 h-20 bg-black">
            <HiDocumentPlus className="text-light-background-neww w-14 h-14" />
          </p>
          <p className="text-xl font-bold text-black">Post Your Request</p>
          <p className="text-[18px] text-pretty text-center text-light-text-muted">
            Describe your service needs by filling out a simple form. Include
            details like the type of service, your location, and any specific
            requirements.
          </p>
        </div>
        <p className="xl:mb-14 mb-10 lg:rotate-0 rotate-90">-----------&gt;</p>
        <div className="flex max-w-72 flex-col items-center justify-center gap-5">
          <p className="rounded-full flex items-center justify-center w-20 h-20 bg-light-background-neww">
            <IoDocumentTextOutline className="text-white w-14 h-14" />
          </p>
          <p className="text-xl font-bold text-black">
            Recieve Quotes & Choose
          </p>
          <p className="text-[18px] text-pretty text-center text-light-text-muted">
            Get multiple quotes from qualified service providers tailored to
            your request. Compare their profiles, ratings, and reviews.
          </p>
        </div>
        <p className="xl:mb-14 mb-10 lg:rotate-0 rotate-90">-----------&gt;</p>
        <div className="flex max-w-72 flex-col items-center justify-center gap-5">
          <p className="rounded-full flex items-center justify-center w-20 h-20 bg-black">
            <RiMoneyDollarCircleFill className="text-light-background-neww w-14 h-14" />
          </p>
          <p className="text-xl font-bold text-black">Verify & Earn</p>
          <p className="text-[18px] text-pretty text-center text-light-text-muted">
            Select the best provider for your needs and connect directly via the
            platform to finalize the service details.
          </p>
        </div>
      </animated.div>
    </>
  );
}

export default DescribeSteps;
