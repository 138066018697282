import React from "react";
import AccountDeleteEmailverifyform from "../../../requester/profile/AccountDeleteemailform";
import PropTypes from "prop-types";
import { DialogTitle } from "@headlessui/react";

AccountDeleteEmailverify.propTypes = {
  setNum: PropTypes.func,
};

function AccountDeleteEmailverify({ setNum }) {
  return (
    <div className="bg-white  w-full pb-4 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className=" w-full sm:mt-0 sm:text-left">
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Delete Account
          </DialogTitle>
          <div className="flex h-full justify-center items-center gap-2 w-full">
            <AccountDeleteEmailverifyform setnum={setNum} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDeleteEmailverify;
