import React, { useState } from "react";
import EmailverifyForm from "../../auth/EmailverifyForm";
import PropTypes from "prop-types";
import { DialogTitle } from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";

Window5.propTypes = {
  requestor: PropTypes.object.isRequired,
  emailverified: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  num: PropTypes.number,
  cancel: PropTypes.func,
};

function Window5({ requestor, num, cancel, emailverified, dispatch }) {
  const [loading, setloading] = useState(false);
  return (
    <div className="bg-white w-full  pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button
            disabled={loading}
            onClick={cancel}
            className="absolute disabled:cursor-not-allowed right-4 top-5"
          >
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            disabled={loading}
            className="absolute  disabled:cursor-not-allowed  top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="flex animate-fade h-full items-center justify-center p-5 gap-2 w-full">
            <EmailverifyForm
              loading={loading}
              setloading={setloading}
              requestor={requestor}
              emailverified={emailverified}
              dispatch={dispatch}
              num={num}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window5;
