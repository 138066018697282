import React from "react";
import { Footer, Header } from "../../../components/requester";
import EmailverifyForm from "../../../components/requester/auth/EmailverifyForm";

function Emailverification() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="p-5 flex py-10 justify-center min-h-screen">
        <EmailverifyForm />
      </div>
      <Footer />
    </div>
  );
}

export default Emailverification;
