import { DialogTitle } from "@headlessui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { $crud } from "../../../../utils/CrudFactory";

AccountDeleteConfirm.propTypes = {
  setOpen: PropTypes.func,
  setNum: PropTypes.func,
};

function AccountDeleteConfirm({ setOpen, setNum }) {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const req = JSON.parse(localStorage.getItem("requester"));

  function cancel() {
    setTimeout(() => {
      setNum(1);
    }, 500);
    setOpen(false);
  }

  async function handleDelete() {
    if (!confirm) {
      return;
    }
    try {
      await $crud.delete("requester/delete-account", {
        requesterId: req._id,
      });
      localStorage.clear();
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className="bg-white border w-full pb-4 pt-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="mt-3 w-full sm:mt-0 sm:text-left">
          <DialogTitle
            as="h3"
            className="text-2xl border-b pb-4 w-full text-center font-semibold text-gray-700"
          >
            Delete Account
          </DialogTitle>
          <div className="px-6 animate-fade">
            <div className="flex items-center text-center gap-2 text-stone-500 mt-16">
              <input
                className="w-5 h-5"
                type="checkbox"
                onChange={(e) => {
                  setConfirm(e.target.checked);
                }}
                required
                checked={confirm}
              />
              <p>Are you sure?</p>
            </div>
            <div className="mt-5 flex items-center gap-3 w-full">
              <button
                onClick={handleDelete}
                className="w-full bg-red-500 rounded-md p-2 text-white"
              >
                Delete
              </button>
              <button
                className="w-full bg-blue-500 p-2 rounded-md text-white"
                onClick={() => {
                  cancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDeleteConfirm;
