import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { retrieveData } from "../../helper/requester/functions";
import axios from "axios";
import { postcode_api_url } from "../../constants";

export function useHome() {
  const [search] = useSearchParams();
  const [modalState, setmodalState] = useState({
    open: false,
    accountblocked: false,
    acceptopen: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState([]);
  const [selectService, setSelectService] = useState({});
  const [km, setKm] = useState("1");
  const [pincode, setPincode] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [err, setErr] = useState("");

  const acceptmodal1 = search.get("leadId");
  const acceptmodal2 = search.get("requesterId");

  function handleOpen() {
    if (selectService._id && searchTerm && pincode) {
      setErr("");
      setmodalState((modalState) => {
        return { ...modalState, open: true };
      });
      return;
    }
    setErr("Please select a service!");
  }

  useEffect(() => {
    if (acceptmodal1 && acceptmodal2) {
      setmodalState((modalState) => {
        return { ...modalState, acceptopen: true };
      });
    }
  }, [acceptmodal1, acceptmodal2]);

  useEffect(() => {
    async function fetchdata() {
      try {
        const data = await retrieveData("requester/services");
        setServices(data.services);
      } catch (e) {
        setServices([]);
      }
    }
    fetchdata();
  }, []);

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition(async (data) => {
        const res = await axios.get(
          `${postcode_api_url}/reverse?lat=${data.coords.latitude}&lon=${data.coords.longitude}&format=json`
        );
        setPincode(res.data.address.postcode);
      });
    } catch (e) {
      setPincode("");
    }
  }, []);

  const handleSearch = (event) => {
    setErr("");
    const query = event.target.value;
    setSearchTerm(query);

    // Filter services based on the query
    if (query === "") {
      setFilteredServices([]); // Clear the filtered services when the search term is empty
    } else {
      const filtered = services.filter((service) =>
        service.category.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(filtered); // Set the filtered services
    }
  };

  return {
    modalState,
    setmodalState,
    err,
    handleSearch,
    filteredServices,
    handleOpen,
    searchTerm,
    setSearchTerm,
    setSelectService,
    selectService,
    setFilteredServices,
    pincode,
    setPincode,
    setErr,
    setKm,
    km,
  };
}
