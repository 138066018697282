import React from "react";
import { qualityLeadsIcon } from "../../../assets/buyer/assets";

const leadsData = [
  { title: "Get quality leads", description: "leads a day" },
  { title: "Get quality leads", description: "leads a day" },
  { title: "Get quality leads", description: "leads a day" },
  { title: "Get quality leads", description: "leads a day" },
];

function MarketPlace() {
  return (
    <div className="flex flex-col md:flex-col p-6 sm:p-10 font-Poppins items-center gap-8 lg:flex-row md:justify-evenly">
      {/* Left Section */}
      <div className="flex flex-col items-center sm:items-center sm:text-center text-center sm:text-left md:items-center lg:items-start md:text-center lg:text-start">
        <div className="flex flex-col gap-4 md:w-[528px]">
          <h2 className="font-bold text-lg sm:text-2xl md:text-3xl">
            Join a buzzing marketplace
          </h2>
          <p className="text-sm sm:text-base md:text-lg">
            Hundreds of thousands of small businesses have found new customers
            on Bark
          </p>
        </div>
        <button className="h-10 w-full sm:w-40 sm:h-14 mt-4 font-bold text-white bg-[#00B2D6] rounded-lg">
          Join Them
        </button>
      </div>

      {/* Right Section */}
      <div className="grid gap-6 sm:gap-8 md:grid-cols-2 sm:grid-cols-2">
        {leadsData.map((lead, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center gap-4 bg-[#00B2D633] p-6 rounded-lg"
          >
            <img
              src={qualityLeadsIcon}
              alt="Quality Leads Icon"
              className="w-14 h-16"
            />
            <h2 className="font-bold text-base sm:text-lg">{lead.title}</h2>
            <p className="text-sm sm:text-base">{lead.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
export default MarketPlace;
