import React from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";

function DownloadOption() {
  return (
    <div className="flex w-[300px] flex-col mb-6 gap-3 xl:items-end items-center xl:text-end">
      <p className="font-extralight text-xs">DOWNLOAD OUR APP</p>
      <div className="flex xl:flex-col items-center gap-2 xl:ml-auto">
        <div className="bg-slate-900 flex items-center gap-2 p-2 w-fit h-10 px-3">
          <FaApple className="h-6 w-6" />
          <div className="flex items-center justify-center flex-col">
            <p className="text-[10px] font-extralight">Download now</p>
            <p className="text-xs">App Store</p>
          </div>
        </div>
        <div className="bg-slate-900 flex items-center gap-2 p-2 w-fit h-10 px-3">
          <FaGooglePlay className="h-6 w-6" />
          <div className="flex items-center justify-center flex-col">
            <p className="text-[10px] font-extralight">Download now</p>
            <p className="text-xs">Play Store</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadOption;
