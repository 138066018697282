import React from "react";
import { Header } from "../../../components/buyer";
import RegisterForm from "../../../components/buyer/auth/RegisterForm";

export function RegisterPage() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="p-5 flex py-10 justify-center min-h-screen">
        <RegisterForm />
      </div>
    </div>
  );
}
