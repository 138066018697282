import React from "react";
import PropTypes from "prop-types";

DashboardNav.propTypes = {
  setviewMode: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

function DashboardNav({ setviewMode, active }) {
  return (
    <div className="flex justify-between gap-2 items-center font-Rubik p-2 py-4 sm:p-4 text-white">
      <p className="text-dark-background-accent font-semibold text-[15px] sm:text-3xl ">
        {active ? "Requester Dashboard" : "Referral Dashboard"}
      </p>
      <div className="flex bg-slate-100 rounded-md text-stone-600 font-semibold font-Rubik ">
        <p
          onClick={() => {
            setviewMode(true);
          }}
          className={`${
            active && "bg-blue-400 text-white"
          }  rounded-l-md cursor-pointer sm:p-2 sm:text-base text-xs p-1 px-3 sm:px-4`}
        >
          Requester
        </p>
        <p
          onClick={() => {
            setviewMode(false);
          }}
          className={` ${
            !active && "bg-blue-400 text-white"
          }  rounded-r-md cursor-pointer sm:p-2 sm:text-base text-xs p-1 px-3 sm:px-4`}
        >
          Referral
        </p>
      </div>
    </div>
  );
}

export default DashboardNav;
