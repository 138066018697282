import React from "react";
import Navitems from "./Navitems";

function FooterNav() {
  return (
    <div className="flex w-fit md:w-full flex-wrap justify-center md:items-center gap-5 md:gap-16">
      <Navitems
        title={"FOR CUSTOMERS"}
        options={[
          "FIND A PROFFESSIONAL",
          "HOW IT WORKS",
          "LOGIN",
          "MOBILE APP",
        ]}
      />
      <Navitems
        title={"FOR PROFFESSIONALS"}
        options={["HOW IT WORKS", "PRICING", "HELP CENTER", "MOBILE APP"]}
      />
      <Navitems
        title={"ABOUT"}
        options={["ABOUT PQCKLE", "CAREERS", "BLOG", "PRESS"]}
      />
    </div>
  );
}

export default FooterNav;
