import toast from "react-hot-toast";
import { $crud } from "../../../utils/CrudFactory";

export const validateInput = (value, dispatch) => {
  const phoneRegex = /\b\d{10,15}\b/;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/; // Matches email addresses
  let message;
  if (phoneRegex.test(value) && emailRegex.test(value)) {
    message =
      "Text contains an email address and a phone number, which is not allowed.";
  } else if (phoneRegex.test(value)) {
    message = "Text contains a phone number, which is not allowed.";
  } else if (emailRegex.test(value)) {
    message = "Text contains an email address, which is not allowed.";
  } else {
    message = "";
  }
  dispatch({
    type: "set-error",
    payload: message,
  });
};

export function handle1(startDate, error, dispatch) {
  if (startDate && !error) {
    dispatch({ type: "set-num", payload: 2 });
    return;
  }
  toast.error("Please enter more details!");
}

export function handle2(startDate, value, people, dispatch) {
  if (startDate && value && people) {
    dispatch({ type: "set-num", payload: 3 });
  }
}

export function handle3(textValue, startDate, value, people, error, dispatch) {
  if (textValue && startDate && value && people && !error) {
    dispatch({ type: "set-num", payload: 4 });
  }
}

export async function handle4(
  textValue,
  startDate,
  value,
  people,
  email,
  dispatch
) {
  if (textValue && startDate && value && people && email !== "") {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email!");
      return;
    }

    try {
      dispatch({ type: "set-loading" });
      const { data } = await $crud.post("requester/request-otp", { email });
      dispatch({ type: "req-id", payload: data.requesterId });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: "stop-loading" });
    }
  }
}

export function handle5(
  textValue,
  startDate,
  value,
  people,
  email,
  emailverified,
  dispatch
) {
  if (
    textValue &&
    startDate &&
    value &&
    people &&
    email !== "" &&
    emailverified
  ) {
    dispatch({ type: "set-num", payload: 6 });
  }
}

export function handle7(
  textValue,
  startDate,
  value,
  people,
  email,
  emailverified,
  phone,
  dispatch
) {
  if (
    textValue &&
    startDate &&
    value &&
    people &&
    email !== "" &&
    emailverified &&
    phone
  ) {
    dispatch({ type: "set-num", payload: 8 });
    toast.success("Success");
  }
}

export function handle8(
  textValue,
  startDate,
  value,
  people,
  email,
  emailverified,
  phone,
  terms,
  refer,
  km,
  pincode,
  num,
  referemail,
  refername,
  referphone,
  selectService,
  createlead
) {
  if (
    textValue &&
    startDate &&
    value &&
    people &&
    email !== "" &&
    emailverified &&
    // phone &&
    terms
  ) {
    if (refer) {
      const data = {
        email,
        serviceId: selectService._id,
        pincode,
        distance: Number(km),
        serviceNotes: textValue,
        serviceDate: startDate,
        value: Number(value),
        limit: num,
        referrerEmail: email,
        referredName: refername,
        referredEmail: referemail,
        referredPhone: referphone,
      };
      createlead(data);
    } else {
      const data = {
        email,
        serviceId: selectService._id,
        pincode,
        distance: Number(km),
        serviceNotes: textValue,
        serviceDate: startDate,
        value: Number(value),
        limit: num,
      };
      createlead(data);
    }
  }
}
