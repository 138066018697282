import { DialogTitle } from "@headlessui/react";
import PropTypes from "prop-types";
import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window10.propTypes = {
  terms: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  num: PropTypes.number,
  refer: PropTypes.bool,
  cancel: PropTypes.func,
};

function Window10({ terms, num, cancel, refer, dispatch }) {
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({
                type: "set-num",
                payload: refer ? num - 1 : num - 2,
              });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-12  animate-fade">
            <p className="text-center text-lg mt-5">Terms & Conditions</p>
            <p className="mt-5 sm:text-base text-xs text-justify">
              By posting you agree that Pqckle will give your contact
              information to service providers who will be paying to get this
              service information and you&apos;ll be getting a profit share
              percentage provided you select one of these contractors and the
              provider accepts that you&apos;ve selected them for the service.
              We also create a profile for you on the backend to track your
              activity and payments here.
            </p>
            <div className="mt-5 flex h-5 items-center gap-2 w-full">
              <input
                type="checkbox"
                checked={terms}
                required
                onChange={(e) => {
                  dispatch({
                    type: "set-terms",
                    payload: e.target.checked,
                  });
                }}
                className="outline-none h-full hover:cursor-pointer w-5 rounded-md border-2"
              />
              <p>I Agree.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window10;
