import React from "react";
import { useForm } from "react-hook-form";
import { $crud } from "../../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

ResetPassword.propTypes = {
  requesterId: PropTypes.number.isRequired,
};

function ResetPassword({ requesterId }) {
  const navigate = useNavigate();
  const { handleSubmit, register, formState } = useForm();
  const { errors, isSubmitting } = formState;

  async function resetPassword(datas) {
    try {
      await $crud.post("requester/verify-otp-reset-password", datas);
      navigate("/login", { replace: true });
    } catch (e) {
      return;
    }
  }
  function onSubmit(data) {
    if (!/^\d{4}$/.test(data.otp)) {
      toast.error("OTP must be a 4-digit numeric value.");
      return;
    }
    const datas = {
      otp: Number(data.otp),
      requesterId,
      password: data.password,
    };
    resetPassword(datas);
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="h-fit font-Rubik font-semibold p-5 animate-fade rounded-md border mt-3 w-[500px] bg-[#fcfcff]"
    >
      <p className="text-center text-4xl mt-5 font-semibold text-slate-700">
        Reset Password
      </p>
      <div className="flex mt-3 flex-col gap-5">
        <div className="flex flex-col gap-1">
          <label className="text-lg">OTP</label>
          <input
            {...register("otp", {
              required: "Otp is required!",
              maxLength: {
                value: 4,
                message: "OTP must be 4 digits",
              },
              minLength: { value: 4, message: "OTP must be 4 digits" },
            })}
            placeholder="Enter OTP"
            type="number"
            className=" border-b placeholder:font-normal placeholder:text-sm border-stone-600 bg-transparent outline-none p-2"
          />
          <p className="text-xs font-normal text-red-500">
            {errors?.otp?.message}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-lg">Password</label>
          <input
            {...register("password", {
              required: "Password is required",
              validate: (value) => {
                const rules = [
                  {
                    regex: /[A-Z]/,
                    message: "Include at least one uppercase letter.",
                  },
                  {
                    regex: /[a-z]/,
                    message: "Include at least one lowercase letter.",
                  },
                  { regex: /\d/, message: "Include at least one number." },
                  {
                    regex: /[!@#$%^&*(),.?":{}|<>]/,
                    message: "Include at least one special character.",
                  },
                ];

                for (const rule of rules) {
                  if (!rule.regex.test(value)) return rule.message;
                }
              },
            })}
            placeholder="Enter Password"
            type="password"
            className=" border-b placeholder:font-normal placeholder:text-sm border-stone-600 bg-transparent outline-none p-2"
          />
          <p className="text-xs font-normal text-red-500">
            {errors?.password?.message}
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-lg">Confirm Password</label>
          <input
            {...register("confirmpassword", {
              required: "Confirm Password is required",
              validate: (value, fieldvalues) => {
                if (value !== fieldvalues.password) {
                  return "Password not matching";
                }
              },
            })}
            placeholder="Confirm password"
            type="password"
            className=" border-b placeholder:font-normal placeholder:text-sm border-stone-600 bg-transparent outline-none p-2"
          />
          <p className="text-xs font-normal text-red-500">
            {errors?.confirmpassword?.message}
          </p>
        </div>
        <button
          disabled={isSubmitting}
          className="bg-[#1d198a] disabled:cursor-not-allowed mt-10 border active:bg-blue-900 font-semibold text-white p-2 py-3 rounded-md text-lg"
        >
          {isSubmitting ? <PulseLoader color="#f6f6f6" /> : "Reset"}
        </button>
      </div>
    </form>
  );
}

export default ResetPassword;
