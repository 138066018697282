const InputField = ({ label, type, placeholder, register, errors }) => {
    return (
      <div className="flex flex-col gap-1">
        <label className="text-lg">{label}</label>
        <input
          type={type}
          placeholder={placeholder}
          {...register}
          className="border-[1px] rounded-lg w-[400px] placeholder:font-normal placeholder:text-sm border-gray-600 bg-transparent outline-none p-2"
        />
        {errors && (
          <p className="text-xs px-2 text-red-500 font-normal">
            * {errors.message}
          </p>
        )}
      </div>
    );
  };
 export default InputField;