import React from "react";
import logo from "../../../assets/requester/image.jpg";
import { Link } from "react-router-dom";
import { buyerurl } from "../../../constants";

export function Header() {
  const token = JSON.parse(localStorage.getItem("req-token"));
  return (
    <header className="flex p-5 bg-white px-8 sm:px-16 font-Rubik sticky top-0 z-[100] items-center justify-between">
      <div className="flex relative items-center gap-5">
        <Link to="/">
          <img
            src={logo}
            className="object-contain scale-[2] sm:scale-[2.2] h-8 w-16"
            alt="logo-img"
          />
        </Link>
        {/* <Dropdown /> */}
      </div>
      <div className="md:hidden">
        {token ? (
          <Link
            to="/dashboard"
            className="font-semibold text-sm sm:text-base hover:cursor-pointer hover:underline"
          >
            Go to Dashboard
          </Link>
        ) : (
          <Link
            to="/login"
            className="font-semibold text-sm sm:text-base hover:cursor-pointer hover:underline"
          >
            Login
          </Link>
        )}
      </div>
      <div className="hidden gap-3 md:flex items-center">
        {token ? (
          <Link
            to="/dashboard"
            className="font-semibold text-sm sm:text-base hover:cursor-pointer hover:underline"
          >
            Go to Dashboard
          </Link>
        ) : (
          <>
            <Link
              to="/login"
              className="font-semibold text-sm sm:text-base hover:cursor-pointer hover:underline"
            >
              Login
            </Link>
            <Link
              to={buyerurl}
              className="font-semibold hidden text-sm sm:text-base hover:cursor-pointer hover:underline"
            >
              Join as Proffessional
            </Link>
          </>
        )}
      </div>
    </header>
  );
}
