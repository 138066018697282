import { DialogTitle } from "@headlessui/react";
import React from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdOutlineDateRange } from "react-icons/md";

Window1.propTypes = {
  dispatch: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  cancel: PropTypes.func,
};

function Window1({ dispatch, startDate, cancel }) {
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className=" relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="animate-fade flex flex-col items-center justify-center">
            <p className="mt-16 text-xl text-center">Potential Service Date</p>
            <p className="mt-5 text-xs text-stone-500 text-center">
              Your request will expire after this date!
            </p>
            <div className="mt-2 relative text-center w-60 ">
              <MdOutlineDateRange className="h-5 w-5 absolute right-7 top-[6px] z-20" />
              <DatePicker
                className="text-center border-2 w-full outline-none rounded-md p-1"
                selected={new Date(startDate)}
                minDate={new Date()}
                onChange={(date) => {
                  dispatch({ type: "set-date", payload: date });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window1;
