import { useState } from "react";

const Submit = () => {
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState("");
  const [displayOnProfile, setDisplayOnProfile] = useState(true);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ rating, comment, displayOnProfile });
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      {/* Star Rating */}
      <div className="flex items-center space-x-1 mb-4">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            onClick={() => handleRatingChange(star)}
            className={
              star <= rating
                ? "text-yellow-500 text-xl"
                : "text-gray-300 text-xl"
            }
          >
            &#9733;
          </button>
        ))}
      </div>

      {/* Comment Input */}
      <textarea
        className="w-full p-3 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows="4"
        placeholder="Eg. I need to clean my house this weekend and would like to get someone with their own transport to come. Payment will be in cash and I need someone who has a registered business here or a clear CRC record."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      ></textarea>

      {/* Checkbox */}
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          id="displayOnProfile"
          checked={displayOnProfile}
          onChange={() => setDisplayOnProfile(!displayOnProfile)}
          className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
        />
        <label htmlFor="displayOnProfile" className="ml-2 text-gray-700">
          Display on client profile?*
        </label>
      </div>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Submit
      </button>
    </div>
  );
};

export default Submit;