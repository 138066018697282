import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window11.propTypes = {
  dispatch: PropTypes.func.isRequired,
  num: PropTypes.number,
  setchangePhone: PropTypes.func,
  handle6: PropTypes.func,
  cancel: PropTypes.func,
};

function Window11({ dispatch, cancel, setchangePhone, num, handle6 }) {
  const req = JSON.parse(localStorage.getItem("requester"));
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="animate-fade">
            <p className="mt-8 text-center text-lg">Your Saved Phone Number</p>
            <p className="mt-5 text-center">
              +{req.phone}{" "}
              {req.phoneVerify ? (
                <p className="text-green-500">verified</p>
              ) : (
                <p className="text-red-500">not verified</p>
              )}
            </p>
            {req.phoneVerify ? (
              <div className="mt-5 flex h-5 text-xs sm:text-base justify-center items-center gap-2 w-full">
                <button
                  onClick={() => {
                    setchangePhone(true);
                    dispatch({ type: "set-phone", payload: "" });
                  }}
                  className="rounded-md mt-3 sm:w-52 w-[130px] p-1 sm:p-2 px-5 bg-light-background-neww text-white"
                >
                  Change Phone Number
                </button>
                <button
                  onClick={() => {
                    dispatch({ type: "set-num", payload: 8 });
                  }}
                  className="rounded-md mt-3  sm:w-52 w-[130px] p-1 sm:p-2 px-5 border-2 border-light-background-neww text-light-background-neww"
                >
                  Use Current Number
                </button>
              </div>
            ) : (
              <div className="mt-20 px-10 sm:px-20 text-sm flex-col flex h-5 justify-center gap-2 w-full">
                <button
                  onClick={() => {
                    setchangePhone(true);
                    dispatch({ type: "set-phone", payload: "" });
                  }}
                  className="rounded-md mt-3 p-2 text-[11px] sm:text-base px-5 bg-light-background-neww text-white"
                >
                  Change Phone Number
                </button>
                <div className="flex items-center w-full gap-3">
                  <button
                    onClick={() => {
                      handle6(true);
                    }}
                    className="rounded-md mt-3 p-2 text-[11px] sm:text-base w-full bg-light-background-neww text-white"
                  >
                    Verify Current Number
                  </button>
                  <button
                    onClick={() => {
                      dispatch({ type: "set-num", payload: 8 });
                    }}
                    className="rounded-md mt-3 p-2 text-[11px] sm:text-base w-full bg-light-background-neww text-white"
                  >
                    Continue & Verify Later
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window11;
