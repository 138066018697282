import { DialogTitle } from "@headlessui/react";
import PropTypes from "prop-types";
import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window3.propTypes = {
  error: PropTypes.bool,
  textValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  num: PropTypes.number,
  dispatch: PropTypes.func,
  cancel: PropTypes.func,
};

function Window3({ error, cancel, dispatch, num, textValue, handleChange }) {
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-5 flex flex-col items-center mt-3 animate-fade p-3">
            <p>Add more details about your request</p>
            <div className="mt-2 md:w-96 w-full">
              <div className="space-y-2">
                <textarea
                  placeholder="Enter your details here..."
                  required
                  value={textValue}
                  onChange={handleChange}
                  className="w-full outline-none max-h-[250px] border-2 h-[200px] rounded-md border-stone-400 p-3"
                ></textarea>
                {error && <p className="text-red-500 text-sm">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window3;
