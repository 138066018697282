import React, { useEffect, useRef, useState } from "react";
import { secondsToMinutesAndSeconds } from "../../../utils/services";
import { $crud } from "../../../utils/CrudFactory";
import toast from "react-hot-toast";
import PropTypes from "prop-types";

AccountDeleteEmailverifyform.propTypes = {
  setnum: PropTypes.func.isRequired,
};

function AccountDeleteEmailverifyform({ setnum }) {
  const [emailverified, setemailVerified] = useState(false);
  const inputsRef = useRef([]);
  const [time, setTime] = useState(300);
  const err = "Verification time out!";
  const req = JSON.parse(localStorage.getItem("requester"));

  async function verifyOtp(datas) {
    try {
      await $crud.post("requester/verify-otp", datas);
      setnum(3);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (time !== 0) {
        setTime((time) => time - 1);
      } else {
        clearInterval(id);
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [time]);

  function submitForm(e) {
    e.preventDefault();
    const otp = inputsRef.current.map((obj) => Number(obj.value)).join("");
    if (!/^\d{4}$/.test(otp)) {
      toast.error("OTP must be a 4-digit numeric value.");
      return;
    }
    const data = {
      requesterId: req._id,
      otp: Number(otp),
    };
    verifyOtp(data);
  }

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < inputsRef.current.length - 1) {
      // Move focus to the next input
      inputsRef.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      // Move focus to the previous input if backspace is pressed
      inputsRef.current[index - 1].focus();
    }
  };
  return (
    <form className="font-semibold p-5 rounded-md w-[500px]">
      <p className="text-center text-[20px] sm:text-2xl mt-5 font-semibold text-slate-700">
        Verify Email
      </p>
      <p className="text-center text-sm mt-5 text-stone-500">
        Enter the OTP that we sent to your email
      </p>
      <div className="flex mt-5 flex-col gap-5">
        <div className="flex flex-col gap-1">
          <div className="flex justify-center items-center space-x-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                type="text"
                required
                maxLength="1"
                className="w-12 h-12 text-center text-xl border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                aria-label={`Enter OTP digit ${index + 1}`}
                ref={(el) => (inputsRef.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
              />
            ))}
          </div>
          <p className="text-end text-red-500 font-normal px-28 mt-5">
            {time === 0 ? err : secondsToMinutesAndSeconds(time)}
          </p>
        </div>
        <button
          disabled={emailverified}
          onClick={submitForm}
          className={`bg-light-background-neww ${
            emailverified && "bg-green-300 hover:bg-green-300 text-green-700"
          } mt-5 border font-semibold text-white p-2 py-3 rounded-md text-lg`}
        >
          {emailverified ? "Verified" : "Verify"}
        </button>
      </div>
    </form>
  );
}

export default AccountDeleteEmailverifyform;
