import React from "react";
import PropTypes from "prop-types";

ReviewBox.propTypes = {
  comment: PropTypes.string,
  by: PropTypes.string,
  company: PropTypes.string,
};

function ReviewBox({ comment, by, company }) {
  return (
    <div className="md:w-full md:max-w-full max-w-96 w-full h-68 p-6 bg-[#00B2D633] rounded-2xl">
      <p className="text-5xl font-semibold text-light-background-neww">
        &apos;&apos;
      </p>
      <p className="text-light-text-muted">{comment} </p>
      <p className="text-5xl mt-5 text-end font-semibold text-light-background-neww">
        &apos;&apos;
      </p>

      <div className="text-sm text-end">
        <p>{by}</p>
        <p className="text-light-background-neww">
          <span className="text-light-text-muted font-semibold">CEO of</span>{" "}
          {company}
        </p>
      </div>
    </div>
  );
}

export default ReviewBox;
