import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window9.propTypes = {
  dispatch: PropTypes.func.isRequired,
  referemail: PropTypes.string.isRequired,
  referphone: PropTypes.string.isRequired,
  refername: PropTypes.string.isRequired,
  num: PropTypes.number,
  cancel: PropTypes.func,
};

function Window9({ dispatch, cancel, refername, num, referemail, referphone }) {
  function proceed() {
    if (refername && referemail && referphone)
      dispatch({ type: "set-num", payload: 10 });
  }
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-6 animate-fade relative p-2">
            <p className="mt-2 text-center text-lg">Referral Details</p>
            <div className="mt-2 flex mx-auto md:w-96 flex-col gap-2 w-full">
              <div className="flex flex-col gap-2">
                <label>Referral Name</label>
                <input
                  type="text"
                  required
                  onChange={(e) => {
                    dispatch({ type: "set-ref-name", payload: e.target.value });
                  }}
                  value={refername}
                  placeholder="Enter Referral Name"
                  className="outline-none h-full placeholder:font-normal placeholder:text-sm hover:cursor-pointer w-full p-2 rounded-md border-2"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Referral Email</label>
                <input
                  type="email"
                  onChange={(e) => {
                    dispatch({
                      type: "set-ref-email",
                      payload: e.target.value,
                    });
                  }}
                  required
                  value={referemail}
                  placeholder="Enter Referral Email"
                  className="outline-none h-full placeholder:font-normal placeholder:text-sm hover:cursor-pointer w-full p-2 rounded-md border-2"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label>Referral Phone Number</label>
                <input
                  type="tel"
                  value={referphone}
                  onChange={(e) => {
                    dispatch({
                      type: "set-ref-phone",
                      payload: e.target.value,
                    });
                  }}
                  required
                  placeholder="Enter Referral Phone Number"
                  className="outline-none h-full placeholder:font-normal placeholder:text-sm hover:cursor-pointer w-full p-2 rounded-md border-2"
                />
              </div>
              <button
                onClick={() => {
                  proceed();
                }}
                className="rounded-md mt-3 p-2 px-3 bg-light-background-neww text-white"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window9;
