import announcementIcon from './announcement.png'
import buildingIcon from './building.png'
import developmentIcon from './development.png'
import gardeningIcon from './Gardening.png'
import houseCleaningIcon from './house_cleaning.png'
import photoIcon from './photo.png'
import webDesignIcon from './web_design.png'
import qualityLeadsIcon from'./qualityLeads.png'
import pqckleLogo from './image.jpg'
import pqckleLogoBlack from './PqckleLogoBlack.png'
import avatar from './avatar.svg'

export {
    announcementIcon,
    buildingIcon,
    developmentIcon,
    gardeningIcon,
    houseCleaningIcon,
    photoIcon,
    webDesignIcon,
    qualityLeadsIcon,
    pqckleLogo,
    pqckleLogoBlack,
    avatar,
}