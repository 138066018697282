import { useNavigate } from "react-router-dom";

function LeadReview() {
  const navigate = useNavigate();

  const leadData = [
    { label: "Lead ID", value: "123112", isRightAligned: true },
    { label: "Service Category", value: "Cleaning" },
    { label: "Service Notes", value: "Clean my house" },
    { label: "Lead Posted", value: "04-Sep-24", isRightAligned: true },
    { label: "Lead Expires", value: "04-Oct-24", isRightAligned: true },
    { label: "Location City", value: "Toronto, ON - Canada" },
    { label: "Value", value: "$1", isRightAligned: true },
    { label: "No of people to contact", value: "6", isRightAligned: true },
    {
      label: "Lead cost",
      value: "5.00",
      isRightAligned: true,
      isHighlighted: true,
    },
    { label: "Phone", value: "Phone Verified" },
    { label: "Email Verified", value: "Email Verified" },
    { label: "ID Verified", value: "ID Verified" },
    { label: "How Many Bought", value: "3", isRightAligned: true },
    { label: "How Many Left", value: "3", isRightAligned: true },
  ];

  const contactData = [
    { label: "Contact person's first name", value: "Brad" },
    { label: "Last name", value: "Retracted", isHighlighted: true },
    { label: "Email", value: "brad@tridel.com" },
    { label: "Phone", value: "1 (647) 448-5481" },
  ];

  return (
    <div className="max-w-full sm:max-w-2xl mx-auto p-6 mt-8">
      <div className="bg-white rounded-lg shadow-xl">
        <div className="border border-gray-400 overflow-x-auto mb-9">
          <table className="w-full text-sm">
            <tbody>
              {leadData.map(
                ({ label, value, isRightAligned, isHighlighted }, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 ${
                      isHighlighted ? "bg-green-50" : ""
                    }`}
                  >
                    <td className="border-r border-gray-200 p-2 font-medium">
                      {label}
                    </td>
                    <td className={`p-2 ${isRightAligned ? "text-right" : ""}`}>
                      {value}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        <div className="mt-6 border border-gray-400">
          <table className="w-full text-sm border border-gray-200">
            <tbody>
              {contactData.map(({ label, value, isHighlighted }, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-200 ${
                    isHighlighted ? "bg-red-50" : ""
                  }`}
                >
                  <td className="border-r border-gray-200 p-2 font-medium">
                    {label}
                  </td>
                  <td className="p-2">{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 text-center text-gray-600 font-medium">
          Status – Accepted By Client
        </div>

        <div className="mt-6 flex justify-around pb-6">
          <button
            className="w-1/2 bg-green-500 hover:bg-green-700 text-white font-medium py-2 px-6 rounded-md transition-colors duration-200"
            onClick={() => navigate("/submit")}
          >
            Review
          </button>
        </div>
      </div>
    </div>
  );
}

export default LeadReview;
