import React from "react";
import img1 from "../../../assets/requester/serviceimg1.jpg";
import img2 from "../../../assets/requester/serviceimg2.jpg";
import img3 from "../../../assets/requester/serviceimg3.jpg";
import img4 from "../../../assets/requester/serviceimg4.jpg";
import { IoHomeSharp } from "react-icons/io5";
import { FaHandHoldingHeart } from "react-icons/fa";

import {
  MdOutlineEventAvailable,
  MdHomeRepairService,
  MdOutlinePets,
} from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { MdDriveEta } from "react-icons/md";
import ServiceRow from "./discover/ServiceRow";
import DescribeSteps from "./discover/DescribeSteps";
import ReviewRow from "./discover/ReviewRow";
import { useDiscover } from "../../../hooks/requester/useDiscover";
import { animated } from "@react-spring/web";

const posts = [
  {
    id: 1,
    title: "Gardening",
    imageUrl: img1,
  },
  {
    id: 2,
    title: "Web Design",
    imageUrl: img2,
  },
  {
    id: 3,
    title: "Accounting",
    imageUrl: img3,
  },
  {
    id: 4,
    title: "House Cleaning",
    imageUrl: img4,
  },
];

const services = [
  {
    name: "Home & Garden",
    icon: <IoHomeSharp className="text-white h-10 w-10" />,
  },
  {
    name: "Health & Well Being",
    icon: <FaHandHoldingHeart className="text-white h-10 w-10" />,
  },
  {
    name: "Wedding & Events",
    icon: <MdOutlineEventAvailable className="text-white h-10 w-10" />,
  },
  {
    name: "Bussiness Services",
    icon: <MdHomeRepairService className="text-white h-10 w-10" />,
  },
  {
    name: "Accounting",
    icon: <MdAccountBalance className="text-white h-10 w-10" />,
  },
  {
    name: "Web Design",
    icon: <MdHomeRepairService className="text-white h-10 w-10" />,
  },
  {
    name: "Pet Caring",
    icon: <MdOutlinePets className="text-white h-10 w-10" />,
  },
  {
    name: "Car Driving",
    icon: <MdDriveEta className="text-white h-10 w-10" />,
  },
];

export default function Discover() {
  const { ref1, ref2, inView2, ref3, inView3, ref4, inView4 } = useDiscover();
  const resuableanimateclass = "animate-fade scale-100 animate-duration-[1.8s]";
  const reusableclass =
    "justify-center w-full transition-transform duration-[500ms] scale-0 items-center";
  return (
    <div className="bg-white mt-7 font-Poppins py-2 pb-24 sm:pb-32">
      <div className="mx-auto md:px-2 px-6">
        <div className="mx-auto mt-20 md:mt-20 sm:mt-24 text-center lg:mx-0">
          <h2 className="text-pretty text-[22px] md:text-[39px] font-bold tracking-tight text-dark-text-neww">
            Connecting you with{" "}
            <span className="text-light-text-neww">Quality</span> Service
            Providers
          </h2>
          <p className="mt-2 text-xs sm:text-sm md:text-lg text-gray-600">
            Lets connect to grow your business with our services providers.
          </p>
        </div>
        <div className="mt-10">
          {/* // className={`${
            //   inView1 && "animate-fade translate-x-0 animate-duration-[1.8s]"
            // } mx-auto flex -translate-x-full transition-transform duration-[800ms] justify-center flex-wrap gap-x-5 gap-y-16 pt-3 lg:mx-0 lg:max-w-none  `} */}
          <animated.div
            ref={ref1}
            className={`mx-auto mt-[80px]  flex transition-transform duration-[800ms] justify-center flex-wrap gap-x-7 gap-y-16 pt-3 lg:mx-0 lg:max-w-none  `}
          >
            {posts.map((post, i) => (
              <div
                className="rounded-lg px-3 hover:scale-[1.03] w-72 md:w-[350px] transition-all duration-300 shadow-lg h-[210px]"
                key={i}
              >
                <p className="p-3 px-4 text-lg font-semibold text-slate-700">
                  {post.title}
                </p>
                <img
                  src={post.imageUrl}
                  className="w-full rounded-lg h-36 object-cover"
                  alt="post-img"
                />
              </div>
            ))}
          </animated.div>
          <DescribeSteps />
          <div className="flex flex-col items-center">
            <animated.div
              ref={ref2}
              className={`flex mt-[70px] md:mt-[130px]  ${
                inView2 && resuableanimateclass
              } ${reusableclass}`}
            >
              <div className="flex flex-col text-center gap-2 items-center">
                <p className="text-dark-background-secondary font-bold text-xl md:text-4xl">
                  Matching Your Needs To The Best Service,{" "}
                  <span className="text-light-background-neww">
                    Every Time.
                  </span>
                </p>
              </div>
            </animated.div>
            <div className="items-center max-w-7xl  flex gap-6 mt-10 md:mt-16 flex-wrap w-full justify-center">
              {services.map((obj, i) => (
                <div
                  key={i}
                  className={` ${
                    inView2 && "translate-x-0"
                  } -translate-x-full transition-transform flex flex-col items-center justify-center duration-[500ms] bg-[#00B2D633] w-64 p-5 h-44`}
                >
                  <div className="w-20 flex items-center justify-center h-20 bg-light-background-neww">
                    {obj.icon}
                  </div>
                  <p className="mt-5 font-semibold">{obj.name}</p>
                </div>
              ))}
            </div>
          </div>
          <animated.div
            ref={ref3}
            className={`flex mt-[70px] md:mt-[130px]  ${
              inView3 && resuableanimateclass
            } ${reusableclass}`}
          >
            <div className="flex flex-col text-center gap-2 items-center">
              <p className="text-dark-background-secondary font-bold text-xl md:text-4xl">
                Explore Top Services
                <span className="text-light-background-neww">
                  {" "}
                  Tailored to Your Needs
                </span>
              </p>
            </div>
          </animated.div>
          <div className="flex flex-col items-center">
            <animated.div
              ref={ref4}
              className={`mt-10 md:mt-16 max-w-7xl flex flex-col gap-16 xl:gap-10 ${
                inView4 && "animate-fade animate-duration-[1.8s]"
              }`}
            >
              <ServiceRow posts={posts} title={"House & Garden"} />
              <ServiceRow posts={posts} title={"Health & Wellness"} />
              <ServiceRow posts={posts} title={"Bussiness"} />
              <ServiceRow posts={posts} title={"Events & Entertainers"} />
            </animated.div>
            <ReviewRow />
          </div>
        </div>
      </div>
    </div>
  );
}
