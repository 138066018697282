import { format } from "date-fns";
import React, { useState } from "react";
import AccountDeleteModal from "../modal/AccountDeleteModal";

function Profilecard() {
  const [deletemodal, setdeletemodal] = useState(false);
  const requester = JSON.parse(localStorage.getItem("requester"));
  return (
    <div className="rounded-3xl font-Rubik  border border-stone-300 shadow-md w-fit md:w-[750px] h-96 bg-stone-50">
      <div className="md:p-3 p-1 flex flex-col gap-1 items-center justify-center mt-20">
        <p className="font-semibold text-2xl text-blue-950">
          {requester.name || "Name"}
        </p>
        <p className="font-semibold mt-1 text-sm text-blue-950">
          {requester.email}
        </p>
        <p className="font-semibold flex items-center gap-2 text-sm text-blue-950">
          <span>
            {" "}
            +{String(requester.phone).slice(0, 2)}
            {String(requester.phone).slice(2)}{" "}
          </span>
          <p className="text-xs">
            {requester.phoneVerify ? (
              <p className="text-green-500">Verified</p>
            ) : (
              <p className="text-red-500">Not verified</p>
            )}
          </p>
        </p>
      </div>
      <div className="flex px-5 md:px-8 items-center mt-5 gap-1 md:gap-8 justify-center">
        <div className="flex text-xs md:text-sm font-semibold flex-col items-center justify-center gap-1">
          <p className="text-stone-700">Total Made</p>
          <p className="md:text-xl text-base font-semibold text-blue-950">
            $100
          </p>
        </div>
        <div className="flex text-xs md:text-sm font-semibold flex-col items-center justify-center gap-1">
          <p className="text-stone-700">Transactions</p>
          <p className="md:text-xl text-base font-semibold text-blue-950">
            $100
          </p>
        </div>
        <div className="flex text-xs md:text-sm font-semibold items-center flex-col justify-center gap-1">
          <p className="text-stone-700">Member Since</p>
          <p className="md:text-lg text-base font-semibold text-blue-950">
            {format(new Date(requester.createdAt), "dd/MM/yyyy")}
          </p>
        </div>
        <div className="flex text-xs md:text-sm font-semibold items-center flex-col justify-center gap-1">
          <p className="text-stone-700">Last Payment</p>
          <p className="md:text-xl text-base font-semibold text-blue-950">
            $100
          </p>
        </div>
      </div>
      <div className="flex items-center mt-6 px-10 md:px-40 gap-3 justify-end">
        <button className="p-2 px-6  bg-[#02B2D6] text-white font-semibold rounded-2xl">
          Edit
        </button>
        <button
          onClick={() => setdeletemodal(true)}
          className="p-2 px-6  bg-red-500 text-white font-semibold rounded-2xl"
        >
          Delete
        </button>
      </div>
      <AccountDeleteModal open={deletemodal} setOpen={setdeletemodal} />
    </div>
  );
}

export default Profilecard;
