import React, { useEffect, useRef, useState } from "react";
import { secondsToMinutesAndSeconds } from "../../../utils/services";
import { $crud } from "../../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import PropTypes from "prop-types";
import toast from "react-hot-toast";

LoginEmailverifyForm.propTypes = {
  requesterId: PropTypes.number.isRequired,
  submit: PropTypes.func.isRequired,
};

function LoginEmailverifyForm({ requesterId, submit }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);

  const inputsRef = useRef([]);
  const [time, setTime] = useState(300);
  const err = "Verification time out!";

  function resentOtp() {
    setResent(true);
    setTimeout(() => {
      setResent(false);
    }, 10000);
  }

  async function verifyOtp(datas) {
    try {
      setLoading(true);
      const { data } = await $crud.post("requester/verify-login-otp", datas);
      localStorage.setItem("req-token", JSON.stringify(data.data.token));
      localStorage.setItem("requester", JSON.stringify(data.data.requester));
      navigate("/dashboard");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (time !== 0) {
        setTime((time) => time - 1);
      } else {
        clearInterval(id);
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [time]);

  function submitForm(e) {
    e.preventDefault();
    const otp = inputsRef.current.map((obj) => Number(obj.value)).join("");
    if (!/^\d{4}$/.test(otp)) {
      toast.error("OTP must be a 4-digit numeric value.");
      return;
    }
    const data = {
      requesterId: requesterId,
      otp: Number(otp),
    };
    verifyOtp(data);
  }

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < inputsRef.current.length - 1) {
      // Move focus to the next input
      inputsRef.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      // Move focus to the previous input if backspace is pressed
      inputsRef.current[index - 1].focus();
    }
  };
  return (
    <form className="font-semibold font-Rubik p-5 h-fit animate-fade rounded-md border w-[500px] bg-slate-50">
      <p className="text-center text-4xl mt-5 font-semibold text-slate-700">
        Verify Email
      </p>
      <p className="text-center text-sm mt-5 text-stone-500">
        Enter the OTP that we sent to your email
      </p>
      <div className="flex mt-5 flex-col gap-5">
        <div className="flex flex-col gap-1">
          <div className="flex justify-center items-center space-x-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                type="text"
                required
                maxLength="1"
                className="w-12 h-12 text-center text-xl border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                aria-label={`Enter OTP digit ${index + 1}`}
                ref={(el) => (inputsRef.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
              />
            ))}
          </div>
          <p className="text-end text-red-500 font-normal px-28 mt-5">
            {time === 0 ? err : secondsToMinutesAndSeconds(time)}
          </p>
        </div>
        <p
          className={`text-end text-sm bg-blue-100 cursor-pointer p-1 px-3 w-fit ml-auto rounded-md font-normal  ${
            resent ? "text-green-500" : "text-red-500"
          }`}
          onClick={() => {
            if (resent) return;
            setTime(300);
            submit();
            resentOtp();
          }}
        >
          {resent ? "Otp sent successfully!" : "Resent"}
        </p>
        <button
          onClick={submitForm}
          disabled={loading}
          className="bg-light-background-neww mt-5 border active:bg-light-background-accent font-semibold text-white p-2 py-3 rounded-md text-lg"
        >
          {loading ? <PulseLoader color="#f6f6f6" /> : "Verify"}
        </button>
      </div>
    </form>
  );
}

export default LoginEmailverifyForm;
