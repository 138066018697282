import React from 'react';

function SignUp() {
  return (
    <div className="flex flex-col items-center justify-center font-Poppins py-10 sm:py-16 md:py-20 px-4">
      <h1 className="text-[24px] sm:text-[28px] md:text-[30px] font-medium text-center">
        Join Pqckle
      </h1>
      <p className="text-center text-sm sm:text-base mt-2">
        Post Your Service and get Clients!
      </p>
      <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mt-6 w-full sm:w-auto">
        <input
          required
          type="email"
          placeholder="Enter your email"
          className="w-full sm:w-[300px] md:w-[400px] h-12 px-4 border-2 border-gray-300 placeholder:font-normal placeholder:text-sm rounded-2xl outline-none shadow-sm"
        />
        <button className="w-full sm:w-auto h-12 px-8 bg-[#00B2D6] text-white rounded-2xl font-light shadow-md hover:font-medium text-sm sm:text-base">
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default SignUp;