import PropTypes from "prop-types";
import React from "react";

CloseReqButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  reason: PropTypes.string,
  select: PropTypes.string,
};

function CloseReqButton({ onClick, label, reason, select }) {
  return (
    <button
      className={`p-2 ${
        reason === select &&
        "bg-light-background-accent border-dark-background-neww"
      } border rounded-md border-light-background-neww`}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default CloseReqButton;
