import React, { useEffect, useState } from "react";
import { DialogTitle } from "@headlessui/react";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import Window11 from "./Window11";
import { IoCloseCircleOutline } from "react-icons/io5";
function getUniqueSortedDialCodes(countries) {
  const uniqueDialCodes = [
    ...new Set(
      countries.map((country) => country.dial_code.replace(/\D/g, ""))
    ),
  ];
  uniqueDialCodes.sort((a, b) => a - b);
  const data = uniqueDialCodes.map((code) => `+${code}`);
  return data;
}

Window6.propTypes = {
  phone: PropTypes.string.isRequired,
  pherror: PropTypes.string,
  handle6: PropTypes.func.isRequired,
  phonecode: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  num: PropTypes.number,
  cancel: PropTypes.func,
};

function Window6({
  phonecode,
  dispatch,
  cancel,
  phone,
  num,
  pherror,
  handle6,
}) {
  const [codes, setcodes] = useState([]);
  const [changePhone, setchangePhone] = useState(false);

  function handlesix(value) {
    if (!phone) {
      return;
    }
    handle6(value);
  }

  useEffect(() => {
    async function fetchcodes() {
      try {
        const res = await fetch(
          "https://countriesnow.space/api/v0.1/countries/codes"
        );
        const data = await res.json();
        setcodes(getUniqueSortedDialCodes(data.data));
      } catch (e) {
        setcodes([]);
      }
    }
    fetchcodes();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("requester")) {
      dispatch({
        type: "set-phone",
        payload: JSON.parse(localStorage.getItem("requester")).phone,
      });
    }
  }, [dispatch]);

  const validatePhone = (value) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$|^\d{10}$/;

    if (!value || phoneRegex.test(value)) {
      dispatch({ type: "set-pherr", payload: "" });
    } else {
      dispatch({ type: "set-pherr", payload: "Invalid phone number" });
    }

    dispatch({ type: "set-phone", payload: value });
  };

  if (!changePhone && localStorage.getItem("requester"))
    return (
      <Window11
        cancel={cancel}
        dispatch={dispatch}
        handle6={handle6}
        setchangePhone={setchangePhone}
        num={num}
      />
    );
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex  w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              if (changePhone && localStorage.getItem("requester")) {
                setchangePhone(false);
              } else {
                dispatch({ type: "set-num", payload: num - 1 });
              }
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-10 animate-fade">
            <p className="mt-10 text-center text-lg">Your Phone Number</p>
            {/* <p className="mt-2 ">Enter your Phone</p> */}
            <div className="mt-2 flex justify-center items-center w-full">
              <div className="max-w-sm flex items-center gap-3">
                <div className="relative flex items-center h-full">
                  <select
                    value={phonecode}
                    required
                    onChange={(e) => {
                      dispatch({
                        type: "set-phone-code",
                        payload: e.target.value,
                      });
                    }}
                    className="outline-none mt-2 rounded-md p-2 px-4 pr-7 appearance-none border-2"
                  >
                    {codes.map((obj, i) => {
                      return (
                        <option key={i} value={obj}>
                          {obj}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.2"
                    stroke="currentColor"
                    className="h-5 w-5 ml-1 absolute top-5 right-1 text-slate-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  required
                  value={phone}
                  onChange={(e) => validatePhone(e.target.value)}
                  placeholder="Enter your phone number"
                  className={`outline-none mt-2 rounded-md w-full sm:w-[390px] p-2 px-4 border-2 ${
                    pherror ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>
            </div>
            {pherror && <p className="text-red-500 text-sm mt-2">{pherror}</p>}
          </div>
          <div className="flex gap-4 flex-col items-center justify-center mt-36 mb-2">
            <button
              className="p-3 px-5 md:w-96 w-52 bg-light-background-neww rounded-md text-white"
              onClick={() => {
                handlesix(true);
              }}
            >
              Next
            </button>
            {/* <button
              onClick={() => {
                handlesix(false);
              }}
              className="p-3 px-5 md:w-96 w-52 bg-light-background-neww rounded-md  text-sm text-white"
            >
              Verify Later
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window6;
