import React from "react";
import ReviewBox from "./ReviewBox";

function ReviewRow() {
  return (
    <div className="flex max-w-[1230px] md:flex-row flex-col items-center mt-20 justify-center gap-5">
      <ReviewBox
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sit donec
        lectus suscipit ultricies rhoncus. Egestas platea in mauris urna gravida
        odio nam quisque"
        by={"Bill Gates"}
        company={"Microsoft"}
      />
      <ReviewBox
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sit donec
        lectus suscipit ultricies rhoncus. Egestas platea in mauris urna gravida
        odio nam quisque"
        by={"Mark"}
        company={"Facebook"}
      />
      <ReviewBox
        by={"Elon Musk"}
        comment="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sit donec
        lectus suscipit ultricies rhoncus. Egestas platea in mauris urna gravida
        odio nam quisque"
        company={"X"}
      />
    </div>
  );
}

export default ReviewRow;
