import React from "react";
import { Footer, Header } from "../../components/requester";
import Main from "../../components/requester/home/Main";
import Discover from "../../components/requester/home/Discover";

export function Home() {
  return (
    <div className="min-h-screen font-Rubik">
      <Header />
      <Main />
      <Discover />
      <Footer />
    </div>
  );
}
