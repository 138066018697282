import React from "react";
import logo from "../../assets/requester/logo.jpeg";
import img from "../../assets/requester/comingImg.svg";

function Coming() {
  const handleContactClick = () => {
    window.open("https://pqxel.com/contact/request-services/", "_blank");
  };
  return (
    <div className="flex h-screen font-Poppins w-full">
      <div className="flex-1 bg-commingbg bg-cover md:bg-top bg-center bg-no-repeat">
        <img
          src={logo}
          className="ml-[20px] w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]"
          alt="logo-img"
        />
        <div className="flex relative flex-col mt-10 md:mt-7 items-center justify-center">
          <p className="md:text-3xl text-xl font-semibold">
            WE ARE COMING SOON!!
          </p>
          <p className="text-stone-500 mt-2 text-sm md:text-lg">
            Stay tuned for something amazing
          </p>
          <img
            className="md:w-[400px] w-[200px] h-[200px]  md:scale-150 md:mt-4 mt-2 md:h-[300px]"
            src={img}
            alt="coming-soon-img"
          />
          <button
            onClick={handleContactClick}
            className="bg-light-background-neww w-80 sm:w-96 z-50 top-[470px] mt-3 md:mt-5 rounded-md font-semibold p-2 px-5 text-white"
          >
            Contact Us
          </button>
          <p className="text-lg text-light-text-muted mt-[190px] sm:mt-[220px] md:mt-28">
            © Copyright Pqckle | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Coming;
