import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { validateEmail } from "./Validation";
import InputField from "./InputField";

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  function onSubmit(data) {
    console.log(data);
    const email = data.email;
    const password = data.password;
    localStorage.setItem("email", JSON.stringify(email));
    localStorage.setItem("password", JSON.stringify(password));
  }

  function onError(errors) {
    console.error("Validation Errors:", errors);
  }

  return (
    <div className="flex flex-col justify-center items-center h-fit bg-[#00B2D6] rounded-lg">
      <h2 className="text-center text-2xl font-bold text-white mb-6 bg-[#00B2D6] mt-4">
        Login
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="w-full max-w-md bg-white p-6 rounded-b-lg shadow-md"
      >
        <div className="space-y-4">
          <InputField
            label="Email"
            type="email"
            placeholder="Enter your email"
            register={register("email", {
              required: "Email is required",
              validate: (value) => validateEmail(value),
            })}
            errors={errors.email}
          />

          <button
            type="submit"
            onClick={() => navigate("/leads/Buyeremailverification")}
            className="w-full bg-[#00B2D6] font-bold text-white py-2 rounded-lg hover:bg-sky-600 transition"
          >
            Login
          </button>
        </div>

        <div className="mt-4 text-center text-sm text-gray-600">
          <p>
            Don't have an account?{" "}
            <Link to="/register" className="text-blue-500 hover:underline">
              Register here
            </Link>
          </p>
        </div>

        <div className="mt-2 text-center text-xs text-gray-400">
          <p>
            By logging in, you agree to our Terms of Service and Privacy Policy.
          </p>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
