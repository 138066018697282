import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function BuyLead() {

    const navigate = useNavigate()
  const leadData = [
    { label: "Lead ID", value: "123112", isRightAligned: true },
    { label: "Service Category", value: "Cleaning" },
    { label: "Service Notes", value: "Clean my house" },
    { label: "Lead Posted", value: "04-Sep-24", isRightAligned: true },
    { label: "Lead Expires", value: "04-Oct-24", isRightAligned: true },
    { label: "Location City", value: "Toronto, ON - Canada" },
    { label: "Value", value: "$1", isRightAligned: true },
    { label: "No of people to contact", value: "6", isRightAligned: true },
    {
      label: "Lead cost",
      value: "5.00",
      isRightAligned: true,
      isHighlighted: true,
    },
    { label: "Phone", value: "Phone Verified" },
    { label: "Email Verified", value: "Email Verified" },
    { label: "ID Verified", value: "ID Verified" },
    { label: "How Many Bought", value: "3", isRightAligned: true },
    { label: "How Many Left", value: "3", isRightAligned: true },
  ];

  return (
    <div className="max-w-full sm:max-w-2xl mx-auto p-6 mt-8">
      <div className="bg-white rounded-lg shadow-md">
        <div className="border border-gray-200 overflow-x-auto">
          <table className="w-full text-sm">
            <tbody>
              {leadData.map(
                ({ label, value, isRightAligned, isHighlighted }, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 ${
                      isHighlighted ? "bg-green-50" : ""
                    }`}
                  >
                    <td className="border-r border-gray-200 p-2 font-medium">
                      {label}
                    </td>
                    <td className={`p-2 ${isRightAligned ? "text-right" : ""}`}>
                      {value}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-6 flex justify-center pb-6">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-8 rounded-md transition-colors duration-200" onClick={() => navigate('/payandbuylead') }>
            Buy This Lead
          </button>
        </div>
      </div>
    </div>
  );
}

// BuyLead.propTypes = {
//   leadData: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       value: PropTypes.string.isRequired,
//       isRightAligned: PropTypes.bool,
//       isHighlighted: PropTypes.bool,
//     })
//   ).isRequired,
// };

export default BuyLead;
