import { DialogTitle } from "@headlessui/react";
import { format } from "date-fns";
import React from "react";
import { $crud } from "../../../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";

function DeletedAccountWindow() {
  const navigate = useNavigate();

  const req = JSON.parse(localStorage.getItem("requester"));

  async function handleRetrieve() {
    try {
      await $crud.put("requester/retrieve-account", {
        requesterId: req._id,
      });
      localStorage.clear();
      navigate("/login");
    } catch (e) {
      console.error(e);
    }
  }

  async function handleLogout() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className="bg-white border w-full min-h-[300px] px-4 pb-4 animate-fade pt-10 sm:p-6 sm:pb-4">
      <div className="sm:flex w-full h-full sm:items-start">
        <div className="mt-3 w-full h-full text-center">
          <DialogTitle
            as="h3"
            className="text-2xl w-full text-center font-semibold text-gray-600"
          >
            Account Status
          </DialogTitle>
          <p className="mt-5 text-slate-700">
            Your account is set to delete on{" "}
            {format(new Date(req.deleteAccountDate), "dd/MM/yyyy")}!
          </p>
          <div className="mt-2 w-full flex items-center justify-center">
            <div className="flex mt-5 items-center gap-5">
              <button
                onClick={handleLogout}
                className="bg-green-300 rounded-md p-3 px-5  text-green-800"
              >
                Logout
              </button>
              <button
                onClick={handleRetrieve}
                className="bg-red-300 rounded-md p-3 px-5  text-red-800"
              >
                Retrieve Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletedAccountWindow;
