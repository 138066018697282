import React, { useEffect, useState } from "react";
import { useHome } from "../../../hooks/buyer/useHome";
import { IoSearch } from "react-icons/io5";
import {
  announcementIcon,
  buildingIcon,
  developmentIcon,
  gardeningIcon,
  houseCleaningIcon,
  photoIcon,
  webDesignIcon,
} from "../../../assets/buyer/assets";
import { useNavigate, useSearchParams } from "react-router-dom";
import { retrieveData } from "../../../helper/buyer/functions";
import LeadsList from "./LeadsList";

const kms = [1, 5, 10, 20, 30, 50, 100, 150];

function Main() {
  const [km, setkm] = useState(30);
  const { setSelectService } = useHome();

  const serviceData = [
    { icon: houseCleaningIcon, title: "House Cleaning", count: 100 },
    { icon: webDesignIcon, title: "Web Design", count: 300 },
    { icon: photoIcon, title: "Photography", count: 400 },
    { icon: developmentIcon, title: "Development", count: 500 },
    { icon: buildingIcon, title: "Building", count: 200 },
    { icon: gardeningIcon, title: "Gardening", count: 300 },
    { icon: announcementIcon, title: "Social Media", count: 100 },
  ];

  const LeadCard = ({ icon, title, count }) => (
    <div className="flex flex-col items-center justify-center rounded-lg w-36 sm:w-44 h-36 sm:h-40 p-5 border border-[#00B2D6]">
      <img src={icon} alt={title} className="w-12 h-12" />
      <h3 className="mt-2 text-base">{title}</h3>
      <p className="text-sm">{count}</p>
    </div>
  );

  const [selectedService, setSelectedService] = useState(null);
  const [showError, setShowError] = useState(false);

  const [services, setservices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDistance, setSearchDistance] = useState("");
  const [open, setopen] = useState(false);

  const [canadianCities, setCanadiancities] = useState([]);
  const [city, setCity] = useState("");
  const [search, setsearch] = useSearchParams();

  const err = services?.filter((obj) => {
    return obj.category.includes(searchTerm.category || searchTerm);
  });

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchServices() {
      try {
        const data = await retrieveData("requester/services");
        setservices(data.services);
      } catch (e) {}
    }
    fetchServices();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    // Filter services based on the query
    if (query === "") {
      setFilteredServices([]); // Clear the filtered services when the search term is empty
    } else {
      const filtered = services.filter((service) =>
        service.category.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(filtered); // Set the filtered services
    }
  };
  if (search.get("lead-category")) {
    return <LeadsList />;
  }

  const openBtn = searchTerm ? true : false;

  const handleGoButtonClick = () => {
    navigate(`/leads/leadslist?category=${searchTerm}&distance=${km}`);
    // navigate(`/leadslist?category=${searchTerm}&distance=${km}`);

    if (!selectedService) {
      setopen(openBtn);
      setShowError(true);
    }
  };

  return (
    <div className="bg-white flex flex-col items-center px-6 py-10 sm:px-10 sm:py-16">
      
      <h1 className="text-center text-xl sm:text-4xl font-bold mb-8">
        Leads for <span className="text-[#00B2D6]">Trending services</span>
      </h1>

      {/* Search Filter */}
      <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-6 mb-12">
        <p>Within</p>
        <select
          value={km}
          onChange={(e) => setkm(e.target.value)}
          className="w-32 sm:w-40 border border-gray-300 rounded px-3 py-2 text-sm focus:ring-2 focus:ring-blue-300"
        >
          {kms.map((distance, i) => (
            <option key={i} value={distance}>
              {`${distance} km`}
            </option>
          ))}
        </select>
        <p>from</p>
        <div className="flex flex-col items-center border border-gray-300 rounded px-3 py-2">
          <p className="text-sm">Postal Code</p>
          <p className="text-sm">L5M0J2</p>
        </div>
      </div>

      {/* Services Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-7 gap-4 sm:gap-6 mb-12">
        {serviceData.map((service) => (
          <LeadCard key={service.title} {...service} />
        ))}
      </div>

      {/* Custom Lead Search */}
      <div className="flex items-center w-full justify-center mb-12">
        <div className="flex-grow border-t border-[#00B2D6]" />
        <span className="mx-4 text-sm sm:text-base text-[#00B2D6]">
          Or Start A Custom Lead Search For Your Service Category
        </span>
        <div className="flex-grow border-t border-[#00B2D6]" />
      </div>

      <h2 className="text-center text-lg sm:text-xl font-bold text-[#111D5E] mb-8">
        Get Quality Leads & Register Your Business Service
      </h2>

      {/* Search and Button */}
      <div className="flex flex-col sm:flex-row items-center gap-4 sm:items-start">
        <div className="relative items-center justify-center">
          <input
            type="text"
            className="w-72 h-12 sm:w-80 border border-gray-300 rounded-lg px-4 py-2 text-sm focus:ring-2 focus:ring-blue-300"
            placeholder="What Service do you provide?"
            value={searchTerm}
            onChange={handleSearch}
          />
          <IoSearch className="absolute right-4 top-3 w-6 h-6 text-gray-400" />
          {filteredServices.length > 0 && (
            <ul className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto z-10">
              {filteredServices.map((service, index) => (
                <li
                  key={index}
                  className="px-4 py-2 text-sm hover:bg-blue-100 cursor-pointer"
                  onClick={() => {
                    setSearchTerm(service.category);
                    setSelectService(service);
                    setFilteredServices([]);
                  }}
                >
                  {service.category}
                </li>
              ))}
            </ul>
          )}
          {searchTerm && err.length === 0 && (
            <p className="p-2 text-sm text-red-500">
              The above service is not available!
            </p>
          )}
          {setShowError === true ? (
            <p className="p-2 text-sm text-red-500">Please select a service</p>
          ) : null}
        </div>
        <button
          onClick={() => handleGoButtonClick()}
          className="w-72 sm:w-40 h-12 bg-[#00B2D6] text-white rounded-lg font-semibold shadow-md hover:bg-blue-600"
        >
          Get Started
        </button>
      </div>
    </div>
  );
}

export default Main;
