import React from "react";
import { Footer, Header } from "../../components/buyer";
import Main from "../../components/buyer/home/Main";
import QualityLeads from "../../components/buyer/home/QualityLeads";
import MarketPlace from "../../components/buyer/home/MarketPlace";
import CustomerFeedback from "../../components/buyer/home/CustomerFeedback";
import SignUp from "../../components/buyer/auth/SignUp";

export function Home() {
  return (
    <div className="min-h-screen font-Rubik bg-white">
      <Header />
      <Main />
      {/* <Discover /> */}
      <QualityLeads />
      <MarketPlace />
      <CustomerFeedback />
      <SignUp />
      <Footer />
    </div>
  );
}
