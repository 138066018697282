import axios from "axios";
import { useEffect, useState } from "react";
import { countryCode_api_url, currency_api_url } from "../../constants";

export default function useFetchCurrency() {
  const [currency, setCurrency] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getCurrencyFromIP() {
      try {
        setLoading(true);
        const geoResponse = await axios.get(countryCode_api_url);
        const countryCode = geoResponse.data.country;
        const countryResponse = await axios.get(
          `${currency_api_url}/${countryCode}`
        );
        const countryData = countryResponse.data;
        const currency = Object.keys(countryData[0].currencies)[0];
        setCurrency(currency);
      } catch (error) {
        console.error("Error fetching currency:", error.message);
      } finally {
        setLoading(false);
      }
    }
    getCurrencyFromIP();
  }, []);

  return { currency, loading };
}
