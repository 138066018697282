import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { retrieveData } from "../../helper/buyer/functions";

export function useHome() {
  const [search] = useSearchParams();
  const [modalState, setmodalState] = useState({
    open: false,
    accountblocked: false,
    acceptopen: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [services, setServices] = useState([]);
  const [selectService, setSelectService] = useState({});
  const [canadianCities, setCanadiancities] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [city, setCity] = useState("");
  const [err, setErr] = useState("");

  const [open, setopen] = useState(false);
  const [showError, setShowError] = useState(false);

  const acceptmodal1 = search.get("leadId");
  const acceptmodal2 = search.get("requesterId");

  const openBtn = searchTerm ? true : false;
  const noSearchTerm = !searchTerm;

  function handleOpen() {
    if (!selectService) {
      setopen(openBtn);
      setShowError(true);
      setErr("");
      setmodalState((modalState) => {
        return { ...modalState, open: true };
      });
      return;
    }
    setErr("Please select a service!");
  }

  useEffect(() => {
    async function fetchdata() {
      try {
        const data = await retrieveData("requester/services");
        setServices(data.services);
      } catch (e) {
        setServices([]);
      }
    }
    fetchdata();
  }, []);

  useEffect(() => {
    async function fetchcities() {
      const res = await fetch("https://countriesnow.space/api/v0.1/countries");
      const data = await res.json();
      setCanadiancities(data.data[35].cities);
      setCity(data.data[35].cities[0]);
    }
    fetchcities();
  }, []);

  const handleSearch = (event) => {
    setErr("");
    const query = event.target.value;
    setSearchTerm(query);

    // Filter services based on the query
    if (query === "") {
      setFilteredServices([]); // Clear the filtered services when the search term is empty
    } else {
      const filtered = services.filter((service) =>
        service.category.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(filtered); // Set the filtered services
    }
  };

  return {
    modalState,
    setmodalState,
    city,
    canadianCities,
    err,
    handleSearch,
    filteredServices,
    handleOpen,
    searchTerm,
    setSearchTerm,
    setSelectService,
    selectService,
    setFilteredServices,
    setCity,
  };
}
