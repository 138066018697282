import { DialogTitle } from "@headlessui/react";
import React from "react";
import { $crud } from "../../../../utils/CrudFactory";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

AcceptWindow.propTypes = {
  setopen: PropTypes.func,
};

function AcceptWindow({ setopen }) {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const leadId = search.get("leadId");
  const requesterId = search.get("requesterId");
  async function accept() {
    if (leadId && requesterId) {
      const { data } = await $crud.post("requester/confirm-lead", {
        leadId,
        requesterId,
      });
      localStorage.setItem("req-token", JSON.stringify(data.token));
      localStorage.setItem("requester", JSON.stringify(data.requester));
      localStorage.setItem("isSelfLead", data.isSelfLead);
    }

    setopen(false);
    navigate("/dashboard");
  }
  return (
    <div className="bg-white border w-full min-h-[300px] px-4 pb-4 animate-fade pt-10 sm:p-6 sm:pb-4">
      <div className="sm:flex w-full h-full sm:items-start">
        <div className="mt-3 w-full h-full text-center">
          <DialogTitle
            as="h3"
            className="text-2xl w-full text-center font-semibold text-gray-600"
          >
            Invitation
          </DialogTitle>
          <p className="mt-5 text-slate-700">A person referred you!</p>
          <div className="mt-2 w-full flex items-center justify-center">
            <div className="flex mt-5 items-center gap-5">
              <button
                onClick={() => accept()}
                className="bg-green-300 rounded-md p-3 px-5  text-green-800"
              >
                Accept
              </button>
              <button
                onClick={() => setopen(false)}
                className="bg-red-300 rounded-md p-3 px-5  text-red-800"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptWindow;
