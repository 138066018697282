import React from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import AccountBlockWindow from "./windows/AccountBlockWindow";
import PropTypes from "prop-types";

AccountBlockModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AccountBlockModal({ setOpen, open }) {
  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(!e)}
      className="relative font-Rubik z-[1000]"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-400/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex min-h-full justify-center p-4 text-center items-center sm:p-0"
        >
          <DialogPanel
            transition
            className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <AccountBlockWindow setOpen={setOpen} />
          </DialogPanel>
        </form>
      </div>
    </Dialog>
  );
}
