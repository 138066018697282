import React from "react";
import logo from "../../../assets/requester/image.jpg";
import { Link, useNavigate } from "react-router-dom";

export function DashboardHeader() {
  const navigate = useNavigate();
  function logout() {
    localStorage.clear();
    navigate("/");
  }

  return (
    <header className="flex p-5  bg-white px-8 sm:px-16 shadow-md sticky top-0 z-[100] items-center justify-between">
      <div className="flex relative items-center gap-5">
        <Link to="/">
          <img
            src={logo}
            className="object-contain scale-[2] sm:scale-[2.2] h-8 w-16"
            alt="logo-img"
          />
        </Link>
      </div>
      <div className="flex text-sm gap-5 items-center">
        <p
          onClick={() => {
            navigate("/dashboard");
          }}
          className="font-semibold text-blue-700 hover:cursor-pointer hover:underline"
        >
          My Requests
        </p>
        <img
          src={logo}
          onClick={() => {
            navigate("profile");
          }}
          className="object-contain cursor-pointer scale-[2.2] h-3 w-3 rounded-full border"
          alt=""
        />
        <button
          onClick={logout}
          className="font-semibold hover:cursor-pointer hover:underline"
        >
          Logout
        </button>
      </div>
    </header>
  );
}
