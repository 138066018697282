import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

EmailVerificationBuyer.propTypes = {
  emailVerified: PropTypes.bool,
  requestor: PropTypes.object,
  dispatch: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
};

function EmailVerificationBuyer({ emailVerified, requestor, dispatch, setLoading, loading }) {
  const inputsRef = useRef([]);
  const [time, setTime] = useState(300);
  const errorMessage = "Verification time out!";

  // async function verifyOtp(data) {
  //   try {
  //     setLoading(true);
  //     // Simulate API call
  //     console.log("Verifying OTP", data);
  //     dispatch({ type: "set-email-veri", payload: true });
  //   } catch (e) {
  //     toast.error("Verification failed. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    navigate('/leads/phoneverification')
    e.preventDefault();
    const otp = inputsRef.current.map((input) => input.value).join("");
    if (!/^\d{4,6}$/.test(otp)) {
      toast.error("Please enter a valid OTP.");
      return;
    }

    // verifyOtp({
    //   requesterId: requestor?.requesterId,
    //   otp: Number(otp),
    // });
  };

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-[633px] bg-white p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center border-b pb-4">
          <button className="text-blue-500 text-xl font-bold" onClick={() => navigate(-1)}>&#8592;</button>
          <h2 className="text-center text-xl font-bold text-gray-800">
            Email Verification
          </h2>
          <button className="text-gray-500 text-xl">&times;</button>
        </div>

        <form onSubmit={handleSubmit} className="mt-6 text-center">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Email Verification
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            Enter the verification code (OTP) sent to your email
          </p>

          <div className="flex justify-center gap-3 mb-6">
            {Array.from({ length: 4 }).map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                ref={(el) => (inputsRef.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
                className="w-12 h-12 border-[2px] rounded-md text-center text-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ))}
          </div>

          <p className="text-sm text-gray-600">
            Didn’t receive code?{' '}
            <button
              type="button"
              className="text-blue-500 hover:underline"
              onClick={() => {
                setTime(300); // Reset timer for resend
                toast.success("Code resent to your email.");
              }}
            >
              Resend
            </button>
          </p>

          <p className="text-sm text-red-500 mt-2">
            {time === 0 ? errorMessage : `Time remaining: ${formatTime()}`}
          </p>

          <button
            type="submit"
            disabled={loading}
            className={`w-full mt-6 bg-blue-500 text-white py-3 rounded-lg text-lg font-semibold hover:bg-blue-600 transition ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Verifying..." : "Verify"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailVerificationBuyer;
