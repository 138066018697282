import { DialogTitle } from "@headlessui/react";
import React from "react";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";
import useFetchCurrency from "../../../../hooks/requester/useFetchCurrency";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";

Window2.propTypes = {
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  people: PropTypes.number.isRequired,
  num: PropTypes.number,
  cancel: PropTypes.func,
};

function Window2({ dispatch, num, cancel, value, people }) {
  const { currency, loading } = useFetchCurrency();
  return (
    <div className="bg-white w-full   pb-5 min-h-[400px] sm:pb-4">
      <div className="sm:flex w-full sm:items-start">
        <div className="relative w-full sm:mt-0 sm:text-left">
          <button onClick={cancel} className="absolute right-4 top-5">
            <IoCloseCircleOutline color="white" className="w-6 h-6" />
          </button>
          <button
            className="absolute top-5 left-4 font-normal text-sm"
            onClick={() => {
              dispatch({ type: "set-num", payload: num - 1 });
            }}
          >
            <IoMdArrowBack color="white" className="w-5 h-5" />
          </button>
          <DialogTitle
            as="h3"
            className="sm:text-2xl text-[20px] border-b py-4 w-full text-center font-semibold text-white bg-light-background-neww"
          >
            Post Your Request
          </DialogTitle>
          <div className="px-6 flex mt-8 flex-col mx-auto md:w-96 animate-fade">
            <p className="mt-2 font-semibold text-lg text-center">
              Budget & Requests
            </p>
            <p className="mt-9">Enter the amount/budget</p>
            <p className="absolute mt-[114px] text-[17px] ml-2">
              {loading ? <PulseLoader size="4px" /> : currency}
            </p>
            <input
              value={value}
              required
              onChange={(e) => {
                dispatch({
                  type: "set-value",
                  payload: e.target.value,
                });
              }}
              type="number"
              className="outline-none text-[17px] mt-2 rounded-md p-2 px-4 pl-12 border-2"
            />
            <p className="mt-5">No of people who can contact you</p>
            <div className="mt-2 w-full">
              <div className="relative flex items-center h-full">
                <select
                  value={people}
                  required
                  onChange={(e) => {
                    // setpeople(e.target.value)
                    dispatch({
                      type: "set-people",
                      payload: e.target.value,
                    });
                  }}
                  className="w-full h-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                >
                  {Array.from({ length: 20 }, (_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.2"
                  stroke="currentColor"
                  className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Window2;
