import React from "react";
import logo from "../../../assets/requester/footerlogo.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import FooterNav from "./footer/FooterNav";
import DownloadOption from "./footer/DownloadOption";

export function Footer() {
  return (
    <footer className="p-10 text-white xl:px-24 h-fit bg-black ">
      <div className="flex xl:flex-row flex-col items-center justify-between gap-10 xl:gap-16">
        <div className="flex flex-col xl:items-start items-center w-fit xl:w-[400px] gap-3">
          <img
            src={logo}
            className="w-20 scale-[1.4] xl:scale-125 font-normal h-20"
            alt="logo-img"
          />
          <p>Follow us for more info!</p>
          <div className="flex gap-3 xl:gap-2 items-center">
            <FaFacebook className="xl:w-8 w-10 hover:bg-light-text-neww cursor-pointer bg-slate-900 p-2 h-10 xl:h-8" />
            <FaInstagram className="xl:w-8 w-10 hover:bg-light-text-neww cursor-pointer bg-slate-900 p-2 h-10 xl:h-8" />
            <FaTwitter className="xl:w-8 w-10 hover:bg-light-text-neww cursor-pointer bg-slate-900 p-2 h-10 xl:h-8" />
            <FaYoutube className="xl:w-8 w-10 hover:bg-light-text-neww cursor-pointer bg-slate-900 p-2 h-10 xl:h-8" />
          </div>
        </div>
        <FooterNav />
        <DownloadOption />
      </div>
      <div className="flex mt-7 sm:flex-row flex-col gap-5 sm:gap-28 items-center justify-between">
        <p className="text-xs order-last sm:order-first flex flex-col items-center justify-center xl:text-sm text-light-text-muted">
          &copy; 2025 - All rights reserved by Pqckle.com{" "}
          <span className="xl:text-xs text-[10px]">
            Owned and Managed by Pqxel Inc.
          </span>
        </p>
        <p className="relative">
          <select className="bg-black text-light-muted appearance-none p-1 px-5 w-28 md:w-40 border border-light-text-muted">
            <option value="Canada">Canada</option>
          </select>
          <MdKeyboardArrowDown className="absolute top-[6px] right-3 w-5 h-5" />
        </p>
      </div>
    </footer>
  );
}
