import { DialogTitle } from "@headlessui/react";
import React, { useState } from "react";
import CloseReqButton from "../../../requester/dashboard/CloseReqButton"
import PropTypes from "prop-types";

CloseReqWindow.propTypes = {
  setopen: PropTypes.func,
};

function CloseReqWindow({ setopen }) {
  const reasons = [
    { reason: "someone", label: "Closed with someone else - No pay to you" },
    {
      reason: "pqxel",
      label: <p>Closed with Pqxel&apos; contractor - Get paid by pqckle</p>,
    },
    { reason: "personal", label: "Cancelled for personal reasons" },
  ];
  const [select, setSelect] = useState("");
  return (
    <div className="bg-white border w-full min-h-[300px] px-4 pb-4 animate-fade pt-10 sm:p-6 sm:pb-4">
      <div className="sm:flex w-full h-full sm:items-start">
        <div className="mt-3 w-full h-full text-center">
          <DialogTitle
            as="h3"
            className="text-2xl w-full text-center font-semibold text-gray-600"
          >
            Close Request
          </DialogTitle>
          <p className="mt-5 text-slate-700">Select a reason from below</p>
          <div className="flex px-5 justify-center w-full">
            <div className="mt-2 w-full flex flex-col gap-3 items-start justify-center">
              {reasons.map((obj, i) => {
                return (
                  <CloseReqButton
                    onClick={() => {
                      setSelect(obj.reason);
                    }}
                    key={i}
                    label={obj.label}
                    select={select}
                    reason={obj.reason}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex mt-7 mb-3 px-10 gap-2 items-center justify-center">
            <button
              className="p-1 px-2 text-white w-full bg-blue-500 rounded-md"
              onClick={() => {
                setopen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setopen(false);
              }}
              className="p-1 px-2 text-white w-full bg-red-500 rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloseReqWindow;
