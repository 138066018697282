export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
    return emailRegex.test(email) ? undefined : "Please enter a valid email";
  };
  
  export const validatePassword = (password) => {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
  
    if (password.length < minLength) {
      return "Password must be at least 8 characters long";
    } else if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter";
    } else if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter";
    } else if (!hasDigit) {
      return "Password must contain at least one digit";
    } else if (!hasSpecialChar) {
      return "Password must contain at least one special character";
    } else {
      return undefined; // Password meets all criteria
    }
  };