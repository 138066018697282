import React from "react";
import { Footer, Header } from "../../../components/requester";
import ForgotPassForm from "../../../components/requester/auth/ForgotPassForm";

export function ForgotPassPage() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <div className="p-5 flex py-10 justify-center min-h-screen">
        <ForgotPassForm />
      </div>
      <Footer />
    </div>
  );
}
