import "./Sortabletable";
import SortableTable from "./Sortabletable";

function LeadsList() {
  return (
    <div className="flex items-center p-5 px-40 mt-14 justify-center">
      <div className="flex w-full flex-col gap-5">
        <p className="mb-2 text-xl font-semibold text-stone-700">
          Available Leads
        </p>
        <SortableTable />
      </div>
    </div>
  );
}
export default LeadsList;