import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogBackdrop } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";
import { $crud } from "../../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { useReducer } from "react";
import { reducer } from "../../../helper/requester/services";
import { initialstate } from "../../../helper/requester/services";
import {
  handle1,
  handle2,
  handle3,
  handle4,
  handle5,
  handle7,
  handle8,
  validateInput,
} from "./modalwindowhelper";
import Windows from "./Windows";
import { updatephone } from "../../../helper/requester/apis";

Modalwindow.propTypes = {
  selectService: PropTypes.object,
  pincode: PropTypes.string,
  km: PropTypes.string,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default function Modalwindow({
  selectService,
  pincode,
  km,
  setOpen,
  open,
}) {
  const navigate = useNavigate();
  const [
    {
      num,
      requestor,
      pherror,
      phone,
      error,
      textValue,
      value,
      email,
      people,
      terms,
      startDate,
      emailverified,
      phoneverified,
      phonecode,
      refer,
      refername,
      referemail,
      referphone,
      loading,
    },
    dispatch,
  ] = useReducer(reducer, initialstate);

  async function createlead(datas) {
    try {
      const { data } = await $crud.post("requester/create-lead", datas);
      // setreq(data);
      localStorage.setItem("req-token", JSON.stringify(data.token));
      localStorage.setItem("requester", JSON.stringify(data.requester));
      localStorage.setItem("isSelfLead", data.isSelfLead);
      dispatch({ type: "set-req", payload: data });
      setOpen(false);
      navigate("/dashboard");
    } catch (e) {
      // toast.error(e.message);
      cancel();
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch({ type: "set-text", payload: value });
    validateInput(value, dispatch);
  };

  function cancel() {
    setOpen(false);
    setTimeout(() => {
      dispatch({ type: "set-cancel" });
    }, 500);
  }

  function handle6(verifyPhone = false) {
    if (
      textValue &&
      startDate &&
      value &&
      people &&
      email !== "" &&
      emailverified
      //phone
    ) {
      updatephone(verifyPhone, requestor, phonecode, phone, dispatch);
    }
  }

  function handlenext() {
    const argsMap = {
      1: [startDate, error, dispatch],
      2: [startDate, value, people, dispatch],
      3: [textValue, startDate, value, people, error, dispatch],
      4: [textValue, startDate, value, people, email, dispatch],
      5: [textValue, startDate, value, people, email, emailverified, dispatch],
      6: [],
      7: [
        textValue,
        startDate,
        value,
        people,
        email,
        emailverified,
        phone,
        dispatch,
      ],
      10: [
        textValue,
        startDate,
        value,
        people,
        email,
        emailverified,
        phone,
        terms,
        refer,
        km,
        pincode,
        num,
        referemail,
        refername,
        referphone,
        selectService,
        createlead,
      ],
    };
    const handlerMap = {
      1: handle1,
      2: handle2,
      3: handle3,
      4: handle4,
      5: handle5,
      6: handle6,
      7: handle7,
      10: handle8,
    };
    if (handlerMap[num]) {
      handlerMap[num](...argsMap[num]);
    }
  }
  const hidebottom = num > 4 && num < 10;
  const windowProps = {
    num,
    dispatch,
    startDate,
    value,
    people,
    textValue,
    handleChange,
    error,
    requestor,
    emailverified,
    phone,
    phonecode,
    phoneverified,
    pherror,
    handle6,
    email,
    terms,
    referemail,
    refername,
    referphone,
    cancel,
    loading,
    handlenext,
    hidebottom,
  };
  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(!e)}
      className="relative font-Rubik z-[1000]"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-400/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <Windows properties={windowProps} />
    </Dialog>
  );
}
