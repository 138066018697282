import React from "react";
import PropTypes from "prop-types";

Navitems.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

function Navitems({ title, options }) {
  return (
    <div className="flex sm:mr-0 mr-auto flex-col md:justify-center gap-2">
      <p className="md:text-base text-sm">{title}</p>
      <div className="flex flex-col gap-1 justify-center">
        {options.map((option, i) => {
          return (
            <p key={i} className="text-xs font-extralight">
              {option}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default Navitems;
