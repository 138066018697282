import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import AccountDeleteWindow from "./windows/AccountDeleteWindow";
import React, { useState } from "react";
import AccountDeleteEmailverify from "./windows/AccountDeleteEmailverify";
import AccountDeleteConfirm from "./windows/AccountDeleteConfirm";
import PropTypes from "prop-types";

AccountDeleteModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AccountDeleteModal({ setOpen, open }) {
  const [num, setNum] = useState(1);
  const [email, setEmail] = useState(() => {
    return JSON.parse(localStorage.getItem("requester")).email;
  });

  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(!e)}
      className="relative font-Rubik z-[1000]"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-400/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex min-h-full justify-center p-4 text-center items-center sm:p-0"
        >
          <DialogPanel
            transition
            className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {num === 1 && (
              <AccountDeleteWindow
                setOpen={setOpen}
                setEmail={setEmail}
                setNum={setNum}
                email={email}
              />
            )}
            {num === 2 && <AccountDeleteEmailverify setNum={setNum} />}
            {num === 3 && (
              <AccountDeleteConfirm setNum={setNum} setOpen={setOpen} />
            )}
          </DialogPanel>
        </form>
      </div>
    </Dialog>
  );
}
